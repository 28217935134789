<template>
  <div class="page-container">
    <AuthHeader />
    <div class="navbar-auth-sm">
      <Navbar />
    </div>
    <div class="auth center-vertically">
      <div class="auth-content">
        <div class="title">Forgot Password</div>
        <div class="auth-box">
          <div v-if="errors.length" class="alert alert-danger text-left">{{ errors }}</div>
          <div class="input-group mb-4">
            <input v-model="user.email" type="email" class="form-control" placeholder="Email" />
          </div>
          <button class="btn main-btn-backdrop" type="button" @click="send" :disabled="recaptcha" style="margin-bottom: 10px">
            <img src="../../assets/loader.svg" class="sm-loader" alt v-if="btnLoading" />
            <span v-else>Send</span>
          </button>
          <vue-recaptcha @verify="onVerify" :sitekey="sitekey"></vue-recaptcha>
          <div class="dont-have-text pt-2">
            <router-link to="/login">Have an Account?</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="../../components/common/css/Auth.css">
</style>

<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>

<script>
import Search from "@/components/Search.vue";
import Navbar from "@/components/Navbar.vue";
import AuthHeader from "./AuthHeader.vue";
import axios from "axios";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "ForgotPassword",
  components: {
    Search,
    Navbar,
    VueRecaptcha,
    AuthHeader
  },
  data() {
    return {
      sitekey: '6Lc4S6wUAAAAACQyW8zgRI7swPLCtOF-AXTdKNe9',
      recaptcha: true,
      auth: {
        access_token: "",
      },
      user: {
        email: "",
      },
      btnLoading: false,
      errors: "",
    };
  },
  methods: {
    onVerify: function (response) {
      this.recaptcha = false;
    },
    
    send() {
      //this.$router.push({ name: "reset" });
        this.btnLoading = true;
        axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        };
        const options = {
          url: window.baseURL + "/auth/password/forget",
          method: "POST",
          data: {
            email: this.user.email,
          },
        };
        axios(options)
          .then((res) => {
            this.btnLoading = false;
            localStorage.setItem("forget_email", this.user.email);
            this.$router.push({ name: "reset" });
          })
          .catch((err) => {
            this.btnLoading = false;
            if (err.response && err.response.status == 401) {
              this.errors = err.response.data.error;
            } else {
              this.errors = err.response ? err.response.data.message : "" + err;
            }
          });
    },
  },

  created() {
    if (localStorage.getItem('access_token')) {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>
