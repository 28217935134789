<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">


        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" 
              :style="{backgroundColor:`${rows[0].bgColor} !important`}">
            <div class="align-center">
              <div class="faq-title">
                <div v-html="rows[0].bgTitle"></div>
              </div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${rows[0].image})`}"></div>
        </div>
        

        <div class="program-container">
          <div class="program-row">

            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <a class="link webkit-animation webkit-100-50"></a>
              </div>
              <div class="sidebar-link">
                <a class="link webkit-animation webkit-100-50"></a>
              </div>
              <div class="sidebar-link">
                <a class="link webkit-animation webkit-100-50"></a>
              </div>
              <div class="sidebar-link">
                <a class="link webkit-animation webkit-100-50"></a>
              </div>
            </div>

            <div v-if="!pgLoading" class="program-sidebar">
              <div class="sidebar-link" v-for="(row, index) in rows" :key="index">
                <a :href="'#'+row.slug" class="link">{{ row.title }}</a>
              </div>
            </div>




            <div v-if="pgLoading" class="program-content">
              <div class="faq-container mb-90 pr-5">
                <div class="program-title mb-30 webkit-animation webkit-50-50"></div>
                <div class="description webkit-animation webkit-100-150"></div>
              </div>
              <div class="faq-container mb-90 pr-5">
                <div class="program-title mb-30 webkit-animation webkit-50-50"></div>
                <div class="description webkit-animation webkit-100-150"></div>
              </div>
            </div>

            <div v-if="!pgLoading" class="program-content">
              <div class="faq-container mb-90 pr-5">
                <div :id="row.slug"
                      class="faq-row"
                      v-for="(row, index) in rows"
                      :key="index">
                  <div class="program-title mb-30" v-html="row.title"></div>
                  <div class="description" v-html="row.body"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <Footer />
  </div>
</template>

<style scoped>

@media (max-width: 575.98px) {
  
}

@media (max-width: 767.98px) {
}
@media (min-width: 576px) and (max-width: 767.98px) {
  
}
@media (min-width: 768px) and (max-width: 991.98px) {
  
}

  
  .faq-title {
    display: flex;
    align-items: center;
  }
  .faq-title-bg {
    color: #e0ea79;
    font-weight: bolder;
    font-size: 80px;
  }
  .faq-title-sm {
    line-height: 1;
    color: #a4a4a4;
    font-size: 38px;
    padding-left: 10px;
  }
  .faq-row {
    padding-bottom: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #00000016;
  }
  .faq-row:last-of-type {
    border: none;
    margin: 0;
  }
  .program-content {
    width: calc(100% - 330px);
  }
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .faq-title-bg {
      font-size: 35px;
    }
    .faq-title-sm {
      font-size: 21px;
    }
    .faq-row {
      margin-bottom: 25px;
      padding-bottom: 25px;
    }
    .program-title {
      font-size: 16px;
    }
    .program-header-view {
      background-position: right;
    }
    .program-content {
      width: 100%;
    }
  }

  @media (max-width: 767.98px) {
    .sidebar-link {
      width: 100%;
    }
    .link {
      text-align: center;
    }
    .program-content {
      width: 520px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .faq-title-bg {
      font-size: 50px;
    }
    .faq-title-sm {
      font-size: 25px;
    }
    .program-content {
      width: calc(100% - 230px);
    }
  }

  @media (max-width: 991.98px) {
    .faq-container {
      padding-right: 0 !important;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {

    .faq-title-bg {
      font-size: 52px;
    }
    .faq-title-sm {
      font-size: 25px;
    }
  }

  @media (max-width: 1199.98px) {
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
</style>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import axios from "axios";

export default {
  name: "Faqs",
  components: {
    Navbar: Navbar,
    Footer: Footer,
  },
  data() {
    return {
      rows: [],
      pgLoading: true,
    };
  },
  created() {
    this.fetchRow();
  },
  methods: {

    fetchRow() {
      this.pgLoading = true;
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL+'/faqs',
        method: 'GET',
        data: {},
        params: {},
      }
      axios(options)
        .then(res => {
          this.pgLoading = false;
          this.rows = res.data.rows;
          this.navigation = res.data.navigation;
        })
        .catch(() => {})
        .finally(() => {})
    },

  },
};
</script>
