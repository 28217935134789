<template>
  <div class="partners-container mx-wid-ag">
      <div class="container">
        <div class="ps-content">
          <div class="bg-title text-center" v-html="row.title3"></div>
          <div class="text text-center" v-html="row.body3"></div>
          <div class="grid">
            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo1" :src="row.logo1" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo1" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link1" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn1 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo2" :src="row.logo2" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo2" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link2" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn2 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo3" :src="row.logo3" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo3" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link3" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn3 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo4" :src="row.logo4" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo4" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link4" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn4 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo5" :src="row.logo5" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo5" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link5" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn5 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo6" :src="row.logo6" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo6" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link6" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn6 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo7" :src="row.logo7" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo7" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link7" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn7 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo8" :src="row.logo8" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo8" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link8" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn8 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo9" :src="row.logo9" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo9" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link9" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn9 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo10" :src="row.logo10" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo10" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link10" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn10 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo11" :src="row.logo11" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo11" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link11" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn11 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo12" :src="row.logo12" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo12" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link12" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn12 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo13" :src="row.logo13" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo13" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link13" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn13 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo14" :src="row.logo14" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo14" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link14" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn14 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

            <div v-if="row.logo15" class="grid-card">
              <div class="gc-body d-flex">
                <img v-if="row.logo15" :src="row.logo15" class="gc-logo img-fluid" alt="" />
                <img v-else style="width: auto; display: block; margin: 0 auto" :src="row.logo15" class="gc-logo img-fluid" alt="" />
                <!-- <div class="gc-details">
                  <div class="title" v-html=""></div>
                  <div class="gc-text" v-html=""></div>
                </div> -->
              </div>
              <div class="gc-footer">
                <a class="gc-f-link" :href="row.link15" target="_blank">
                  <div class="d-flex align-items-center">{{ row.btn15 }} <span class="icon-arrow-right icon"></span></div>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>
.partners-container {
  padding: 65px 0;
  background: #F7F7F7;
  border-top: 7px solid #274379;
}
.tc-content {
  padding: 50px 0;
  background-size: cover;
  height: 630px;
}
.bg-title {
  font-size: 33px;
  margin-bottom: 14px;
}
.text {
  color: #6F6F6F;
  line-height: 1.4;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 20px;
}
.tc-btn {
  background: #04CC88;
  color: #fff;
  font-size: 20px;
  padding: 2px 30px;
  line-height: 1;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5,minmax(0,1fr));
  grid-gap: 12.25rem;
  gap: 0.9rem;
  width: 100%;
  padding: 20px 0
}
.grid-card {
  background: #fff;
}
.gc-body {
  padding: 12px
}
.gc-logo {
  /* width: 98px; */
  height: 98px;
}
.gc-details {
  padding-top: 6px;
  padding-left: 10px;
}
.gc-details .title {
  font-weight: bolder;
  font-size: 28px;
  text-transform: uppercase;
  color: #1B1464;
  line-height: 1;
  margin-bottom: 3px;
}
.gc-text {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #1B1464;
}
.gc-footer {
  border-top: 1px solid #DEE5F2;
  padding: 8px 18px;
}
.gc-f-link {
  color: #666;
}
.gc-f-link:hover {
  color: #000;
}
.gc-f-link:active {
  opacity: 0.6;
}
.gc-f-link .icon {
  font-weight: bold;
  margin-left: 10px;
} 

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    
}

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
  .gc-details {
    padding-top: 15px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  .gc-details {
    padding-top: 17px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  .gc-details {
    padding-top: 17px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
</style>

<script>
export default {
  name: "Section3",
  props: ['row'],
  components: {},
  data() {
    return {
      pgLoading: true,
      items: [
        {
          id: 1,
          title: 'AIPS',
          body: 'American Institute of Professional Studies ',
          image: require('../../assets/agmep/aips.png'),
          link: 'https://www.aips.us'
        },
        {
          id: 2,
          title: 'IAOSHE',
          body: 'International AssociationFor Health Occupational',
          image: require('../../assets/agmep/iaoshe.png'),
          link: 'https://www.iaoshe.agmep.net'
        },
        {
          id: 3,
          title: '',
          body: '',
          image: require('../../assets/agmep/ami.png'),
          link: 'https://www.ami.agmep.net'
        },
        {
          id: 4,
          title: 'GAEE',
          body: 'Global Association of Electrical Engineers',
          image: require('../../assets/agmep/gaee.png'),
          link: 'https://www.gaee.agmep.net'
        },
        {
          id: 5,
          title: 'AIHC',
          body: 'American Institute of Healthcare and Hospital Management ',
          image: require('../../assets/agmep/aihc.png'),
          link: 'https://www.aihc.agmep.net'
        },
        {
          id: 6,
          title: 'GITH',
          body: 'Global Institutes of Tourism and Hospitality',
          image: require('../../assets/agmep/gith.png'),
          link: 'https://www.gith.agmep.net'
        },
        {
          id: 7,
          title: 'U.SIA',
          body: 'U.S Industrial Accreditation ',
          image: require('../../assets/agmep/usia.png'),
          link: 'https://www.usia.agmep.net'
        },
        {
          id: 8,
          title: 'GCLA',
          body: 'Global Council for Law, Arbitration and Intellectual Property ',
          image: require('../../assets/agmep/gcla.png'),
          link: 'https://www.gcla.agmep.net'
        },
        {
          id: 9,
          title: 'ASPE',
          body: 'American Society of Professional Engineers ',
          image: require('../../assets/agmep/aspe.png'),
          link: 'https://www.aspe.agmep.net'
        },
        {
          id: 10,
          title: 'SWAC',
          body: 'SouthWestern Accreditation Council ',
          image: require('../../assets/agmep/swac.png'),
          link: 'https://www.swac.agmep.net'
        },
        {
          id: 11,
          title: 'WSF',
          body: 'World Sports Federation ',
          image: require('../../assets/agmep/wsf.png'),
          link: 'https://www.wsf.agmep.net'
        },
        {
          id: 12,
          title: 'IAAMC',
          body: 'International Academy for Art, Media and Creativity ',
          image: require('../../assets/agmep/iaamc.png'),
          link: 'https://www.iaamc.agmep.net'
        },
        {
          id: 13,
          title: 'AISN',
          body: 'American Institute for Special Needs ',
          image: require('../../assets/agmep/aisn.png'),
          link: 'https://www.aisn.agmep.net'
        },
        {
          id: 14,
          title: 'IAHS',
          body: 'International Academy for Homeland Security',
          image: require('../../assets/agmep/iahs.png'),
          link: 'https://www.iahs.agmep.net'
        },
      ]
    };
  },
  created() {
      //
  },
  methods: {
    //
  },
};
</script>
