<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content" style="background: #F7F7F7;">
      <div class="container-fluid">

        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${rows[0].bgColor} !important`}">
            <div class="align-center">
              <div class="faq-title">
                <div v-html="rows[0].bgTitle"></div>
              </div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${rows[0].image})`}"></div>
          <!-- <div class="program-header-view" :style="{backgroundImage:`url(${require('../../assets/img/tc.png')})`}"></div> -->
        </div>

        
        <div class="program-container">
          <div class="program-row">


            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
            </div>

            <!-- <div v-if="!pgLoading" class="program-sidebar">
              <div class="sidebar-link" v-for="(nav, index) in navigation" :key="index">
                  <a :href="'#'+nav.slug" class="link">
                    {{ nav.title }}
                  </a>
              </div>
            </div> -->

            <div v-if="pgLoading" class="program-content program-content-about">
              <div class="program-title webkit-animation webkit-50-50"></div>
              <div class="description-about webkit-animation webkit-100-440"></div>
            </div>

            <div v-if="!pgLoading" class="partners-container mx-wid-ag">
              
                  <div class="grid">
                    <div v-for="(row, index) in logos" :key="index" class="grid-card">
                        <div class="gc-body d-flex" style="border: 1px solid #eee">
                          <router-link :to="'/our-partners/'+row.slug">
                            <img v-if="row.logo_image" :src="row.logo_image" class="gc-logo img-fluid" alt="" />
                          </router-link>
                        </div>
                        <div class="gc-footer">
                          <router-link :to="'/our-partners/'+row.slug" class="gc-f-link">
                            <div class="d-flex align-items-center">Explore 
                              <span class="icon-arrow-right icon"></span>
                            </div>
                          </router-link>
                        </div>
                    </div>
                  </div>  

            </div>

            <!-- Right SideBar -->
            <div v-if="pgLoading" class="accrediation-became">
              <div class="webkit-animation webkit-100-95"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
            </div>
            <div v-if="!pgLoading" class="accrediation-became">

              <p>
            
            </p>

              <!-- Download -->
              <p>
                <div class="program-download" v-if="rows[0].has_download">
                  <div class="download-catalog">
                      <img v-if="rows[0].download_image" 
                          :src="rows[0].download_image" 
                          :alt="rows[0].download_name"
                          class="img-fluid" />
                      <div class="title" style="font-size: 20px;color: #1b1464;text-transform: unset !important">
                          {{ rows[0].download_name }}
                      </div>
                      <a v-if="rows[0].download_file"
                          :href="rows[0].download_file"
                          target="_blank"
                          download class="download-btn">Download
                      </a>
                  </div>
                </div>
              </p>
              <!-- End Download -->

              <!-- Application -->
              
              <!-- End Application -->

              <!-- FAQ -->
              <p class="webkit-mt-2">
                <div class="accred-faq" v-if="rows[0].has_faq">
                    <router-link v-if="rows[0].faq_link" :to="rows[0].faq_link" class="faq-link d-flex mb-30">
                        <div class="faq-bold d-flex align-items-center">FAQ</div>
                        <div class="faq-small d-flex align-items-center">
                            Frequently Asked<br />Questions
                        </div>
                    </router-link>
                </div>
              </p>
              <!-- End FAQ -->

              <p class="webkit-mt-2">
                <div class="training" v-if="rows[0].has_payment">
                  <a v-if="rows[0].payment_link" :href="rows[0].payment_link"
                      target="_blank" 
                      class="read-more"
                      style="border:1px solid #1b1464">
                      <div>{{ rows[0].payment_name }} </div>
                  </a>
                </div>
            </p>


            </div>
            <!-- End Right Sidebar -->

          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>

<style scoped>

.tc-content {
  padding: 50px 0;
  background-size: cover;
  height: 630px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5,minmax(0,1fr));
  grid-gap: 12.25rem;
  gap: 0.9rem;
  width: 100%;
  padding: 20px 0
}
.grid-card {
  background: #fff;
}
.gc-body {
  padding: 12px
}
.gc-logo {
  /* width: 98px; */
  height: 98px;
}
.gc-details {
  padding-top: 6px;
  padding-left: 10px;
}
.gc-details .title {
  font-weight: bolder;
  font-size: 28px;
  text-transform: uppercase;
  color: #1B1464;
  line-height: 1;
  margin-bottom: 3px;
}
.gc-text {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #1B1464;
}
.gc-footer {
  border-top: 1px solid #DEE5F2;
  padding: 8px 18px;
}
.gc-f-link {
  color: #666;
}
.gc-f-link:hover {
  color: #000;
}
.gc-f-link:active {
  opacity: 0.6;
}
.gc-f-link .icon {
  font-weight: bold;
  margin-left: 10px;
} 

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
  .program-row,
  .partners-container {
    width: 100%
  }
  .grid-card {
    max-width: 348px;
  }
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
  .grid {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  .program-row,
  .partners-container {
    width: 100%
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }

  .grid {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  .program-row,
  .partners-container {
    width: 100%
  }
}

  .title-line:after {
    background-color: #4bb3ff !important;
  }

  /* About Program */
  .about-title {
    font-size: 25px;
  }
  .program-articles-rows {
    /*padding-top: 60px;*/
  }

  .description-about { margin-bottom: 70px }

  .program-about-article {
    border-top: 1px solid #00000016;
    padding-top: 27px;
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;
  }
  .program-about-article .small-title,
  .program-about-article .medium-title {
    font-weight: bold;
    font-size: 17px;
  }
  .program-about-article .medium-title {
    font-size: 23px;
    color: #1b1464;
    margin-bottom: 8px;
  }
  .aa-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding-bottom: 50px;
    /*border-bottom: 1px solid #00000016;*/
    flex-wrap: wrap;
  }
  .about-article-box {
    width: 48%;
  }
  .aa-row:last-of-type {
    padding-bottom: 0;
    border: none;
  }
  .aa-row .details {
    width: calc(100% - 400px);
  }
  .aa-row .view {
    width: 320px;
    height: 185px;
  }
  .aa-row .view img {
    width: 100%;
    height: 100%;
  }
  .about-article-box-media {
    position: relative;
  }
  .about-article-box-media video,
  .about-article-box-media img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .about-article-box-media .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-article-box-media .overlay .icon-seek {
    color: #fff;
    font-size: 50px;
  }

  .program-about-article-title {
    position: relative;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 15px;
    color: #1b1464;
    display: inline-block;
  }
  .program-about-article-title:after {
    content: "";
    position: absolute;
    top: -28.5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #4f17a8;
  }

  .program-about-article-text {
    color: #6e6d76;
    font-size: 18px;
  }

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .program-about-article {
      display: block;
    }
    .about-article-box {
      width: 100%;
      margin-bottom: 60px;
    }
    div.aa-row {
      flex-direction: column-reverse;
      padding-top: 0;
    }
    div.aa-row > div {
      width: 100% !important;
    }
    div.aa-row .view {
      margin-bottom: 15px;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 576px) and (max-width: 1199.98px) {
    .aa-row .view {
      width: 270px;
    }
    .aa-row .details {
      width: calc(100% - 300px);
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .grid {
      grid-template-columns: repeat(4,minmax(0,1fr));
    }
  }

  .faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 33px;
  color: #1b1464;
}
</style>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import DownloadCatalog from "../../components/DownloadCatalog.vue";
import axios from "axios";
export default {
  name: "our-partners",
  components: {
    Navbar: Navbar,
    Footer: Footer,
    DownloadCatalog: DownloadCatalog,
  },
  data() {
    return {
      slug: '',
      rows: [],
      logos: [],
      navigation: [],
      pgLoading: true,
    };
  },
  computed: {
  },
  created() {
    if(this.$route.params.slug) {
      this.slug = this.$route.params.slug;
    }
    this.fetchRow();
  },
  methods: {
    
    fetchRow() {
      this.pgLoading = true;
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
      };
      //let baseURL = ;
      // if(this.slug) {
      //   baseURL = window.baseURL+'/about/'+this.slug;
      // }
      const options = {
        url: window.baseURL+'/our-partners',
        method: 'GET',
        data: {},
        params: {},
      }
      axios(options)
        .then(res => {
          this.pgLoading = false;
          this.rows = res.data.rows;
          this.logos = res.data.logos;
        })
        .catch(() => {})
        .finally(() => {})
    },


  },
};
</script>
