<template>
    <div class="auth-big-logo">
      <div class="container-fluid">
        <router-link to="/" class="d-flex align-items-center">
          <img :src="setting.logo.logo" class="img-fluid" />
          <div class="logo-title pl-2" v-html="setting.logo.mini_title"></div>
        </router-link>
      </div>
    </div>
</template>

<style scoped src="../../components/common/css/Auth.css">

</style>

<script>
import axios from "axios";

export default {
    name: "AuthHeader",
    components: {
        
    },
    data() {
        return {
            setting: []
        }
    },
    methods: {
        fetchLogo() {
            axios.get(window.baseURL + '/setting')
            .then(res => {
                console.log(res);
                this.setting = res.data;
            })
            .catch(error => {
                console.log(error);
            })
        },
    },
    created() {
        this.fetchLogo();
    }
}
</script>