<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" 
              :style="{backgroundColor:`${row.bgColor} !important`}">
            <div class="align-center">
              <div class="faq-title">
                <div v-html="row.bgTitle"></div>
              </div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${row.image})`}"></div>
        </div>


        <div class="contactus-container">
          <div class="container">

            
            <div v-if="pgLoading" class="row col-12">
                <div class="col-lg-8 col-sm-12">
                  <div class="webkit-animation webkit-100-440"></div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="webkit-animation webkit-100-440"></div>
                </div>
            </div>

            <div v-if="!pgLoading" class="row">
              <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="contact-form">
                  <div class="description-type mb-40" v-html="row.body1"></div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="contact-address">
                  <div class="contact-text">
                    <div v-html="row.body2"></div>
                    <a v-if="row.link" :href="row.link" target="_blank" class="directions">
                      <div class="d-flex align-items-center" style="margin-top: 20px">
                        Get Directions
                        <span class="icon-back"></span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


              <div class="col-lg-5 col-md-7">
                <div class="form-content">
                  <div class="form-title">Send a Message</div>
                    <div v-if="errors.length" class="alert alert-danger text-left">{{ errors }}</div>
                    <div v-if="success.length" class="alert alert-success text-left">{{ success }}</div>
                  <form v-if="!success.length" @submit.prevent="sendMessage">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.name"
                        placeholder="Name"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.email"
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <textarea
                        type="text"
                        class="form-control"
                        v-model="form.body"
                        placeholder="Subject"
                      ></textarea>
                    </div>
                    <div class="form-group form-group-btn">
                      <button class="btn main-btn-backdrop" :disabled="recaptcha">
                        <img src="../../assets/loader.svg" class="sm-loader" alt v-if="btnLoading" />
                        <span v-else>Submit</span>
                      </button>
                    </div>

                    <vue-recaptcha @verify="onVerify" :sitekey="sitekey"></vue-recaptcha>

                  </form>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>

<style scoped>
.main-btn-backdrop {
  background-color: #004172;
  border: 1px solid #004172;
}

@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}
  .contactus-container {
    padding-top: 80px;
    padding-bottom: 130px;
  }
  .main-program-header {
    
  }

  .program-title {
    font-size: 35px;
    color: #310b7e;
    line-height: 1.2;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
  }
  .form-content {
    padding-top: 70px;
    margin-bottom: 15px;
  }
  .form-title {
    font-weight: bolder;
    color: #000;
    font-size: 17px;
    margin-bottom: 16px;
  }
  .form-content .form-group {
    margin-bottom: 8px;
    border: 1px solid #808080;
    max-width: 100%;
  }
  .form-control {
    resize: none;
  }
  .form-group-btn {
    padding: 0;
    border: none !important;
  }
  .main-btn-backdrop {
    padding: 12px;
  }
  .contact-title {
    font-weight: bold;
    font-size: 18px;
    color: #310b7e;
    margin-bottom: 10px;
  }
  .contact-text {
    color: #4e4d4d;
    font-size: 16px;
  }
  .directions div,
  .icon-back:before {
    color: #0f1dff;
    font-weight: 600;
  }
  .icon-back:before {
    margin-left: 10px;
    font-size: 25px;
  }
  .contact-address {
    padding-top: 50px;
  }

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .form-content .form-group {
      width: 100%;
      max-width: 350px;
    }
    .contact-address {
      margin-top: 40px;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
</style>

<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>
<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import axios from "axios";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "Contact",
  components: {
    Navbar: Navbar,
    Footer: Footer,
    VueRecaptcha
  },
  data() {
    return {
      sitekey: '6Lc4S6wUAAAAACQyW8zgRI7swPLCtOF-AXTdKNe9',
      recaptcha: true,
      form: { name: '', email: '', body: '' },
      errors: false,
      success: false,
      btnLoading: false,
      pgLoading: true,
      row: '',
    };
  },
  watch: {
    $route() {
      this.fetchRow();
    },
  },
  created() {
    this.fetchRow();
  },

  methods: {

    fetchRow() {
      axios
        .get(window.baseURL + "/contacts")
        .then((res) => {
          this.pgLoading = false;
          this.row = res.data.row;
        })
        .catch(() => {})
        .finally(() => {});
    },

    onVerify: function (response) {
      this.recaptcha = false;
    },
    
    sendMessage() {
      this.btnLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL + "/contacts",
        method: "POST",
        data: {
          name: this.form.name,
          email: this.form.email,
          body: this.form.body,
        },
      };
      axios(options)
        .then((res) => {
          this.btnLoading = false;
          this.errors = false;
          this.success = "Message Sent Successfully.";
        })
        .catch((err) => {
          this.btnLoading = false;
          this.success = false;
          this.errors = "Something went wrong.";
        })
        .finally(() => {});
    },
  },
};
</script>
