<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">


        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${row.bgColor1} !important`}">
              <div class="align-center">
                <div class="upper-line" :style="{backgroundColor:`${row.lineColor1} !important`}"></div>
                <div class="certification-line goldColor" v-html="row.bgSubTitle1"></div>
                <div class="title goldColor" v-html="row.bgTitle1"></div>
              </div>
           </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${row.image1})`}"></div>
        </div>


        <div class="program-container">
          <div class="program-row">

            <!-- Left Sidebar -->
            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
            </div>

            <div v-if="!pgLoading" class="program-sidebar">
              <!-- <div class="sidebar-link" v-for="(nav, index) in navigation" :key="index">
                <a :href="'#'+nav.slug" class="link">{{ nav.title }}</a>
              </div>-->
              <div class="sidebar-link">
                <a href="#overview" class="link">Overview</a>
              </div>
              <div class="sidebar-link">
                <a href="#certification-types" class="link">Certification Types</a>
              </div>
              <div class="sidebar-link">
                <a href="#certification-training-program" class="link">Certification of Training Program</a>
              </div>
              <div class="sidebar-link">
                <a href="#certificate-authentications" class="link">Certificate of Authentications</a>
              </div>
            </div>
            <!-- End Left Sidebar -->

            <div v-if="pgLoading" class="program-content">
              <div class="program-title webkit-animation webkit-50-50"></div>
              <div class="description-about webkit-animation webkit-100-440"></div>
            </div>

            <div v-if="!pgLoading" class="program-content">

              <div id="overview" class="overview">
                <div class="description-about" v-html="row.body1"></div>
              </div>

              <div id="certification-types" class="certification-widget">
                 <div class="certificate-widget-details"
                  :style="{backgroundColor:`${row.bgColor2} !important`}">
                    <div class="align-center">
                      <div class="our-text mb-4 goldColor">{{ row.bgSubTitle2 }}</div>
                      <div class="title goldColor">{{ row.bgTitle2 }}</div>
                    </div>
                </div>
                <div class="certificate-widget-view" :style="{backgroundImage: `url(${row.image2})`}"></div>
              </div>

              <div v-if="row.body2" class="bg-title mt-4 mb-5" v-html="row.body2"></div>

              <!-- Certificate 1 -->
              <div id="certification" class="pagination-container mb-80 pt-4">
                <div class="cf-header paleMainColor">{{ row.hint2 }}</div>
                
                <div class="cf-body mb-30">
                  <div class="certificates-width">
                    <div class="cf-title paleMainColor">{{ row.cat1_name.body1 }}</div>
                    <button
                      class="cf-link"
                      v-for="(cert, index) in certificates1"
                      :key="index"
                      @click="toggleDefCerts(cert.image, cert.pdf, cert.id)"
                      v-bind:class="{ 'active-c': cert.id === defActiveItem }"
                    >{{ cert.title }}</button>
                  </div>
                  <div class="certificates-width-view">
                    <a :href="defCerPdf">
                      <img :src="defCertsView" class="img-fluid" alt="Certificate" />
                    </a>
                  </div>
                </div>
                <div class="cf-footer">
                  <div class="cfc-title">Training Program Duration :</div>
                  <div class="cfc-count">{{ row.duration1 }}</div>
                </div>
              </div>

              <div id="professionalCertification" class="pagination-container mb-80 pt-4">
                <div class="cf-body pp-body mb-30">
                  <div class="certificates-height">
                    <div class="cf-title paleMainColor">{{ row.cat2_name.body2 }}</div>
                    <div class="flex-certs">
                      <div class="certs-body">
                        <button
                          class="cf-link"
                          v-for="(cert, index) in certificates2"
                          :key="index"
                          @click="toggleProCerts(cert.image, cert.pdf, cert.id)"
                          v-bind:class="{ 'active-c': cert.id === pdActiveItem }"
                        >{{ cert.title }}</button>
                      </div>

                      <div class="cf-footer">
                        <div class="cfc-title">Training Program Duration:</div>
                        <div class="cfc-count">{{ row.duration2 }}</div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="certificates-height">
                    <div class="cf-title paleMainColor">{{ row.cat3_name.body3 }}</div>
                    <div class="flex-certs">
                      <div class="certs-body">
                        <button
                          class="cf-link"
                          v-for="(cert, index) in certificates3"
                          :key="index"
                          @click="toggleProDipCerts(cert.image, cert.pdf, cert.id)"
                          v-bind:class="{ 'active-c': cert.id === pdActiveItem }"
                        >{{ cert.title }}</button>
                      </div>
                      <div class="cf-footer">
                        <div class="cfc-title">Training Program Duration:</div>
                        <div class="cfc-count">{{ row.duration3 }}</div>
                      </div> -->
                  <!--   </div>
                  </div> -->
                  <div class="certificates-height-view">
                    <a :href="proCerPdf">
                      <img :src="verticalCertsView" class="img-fluid" alt="Certificate" />
                    </a>
                  </div>
                </div>
              </div>



              <!-- Programs -->
              <div id="certification-training-program" class="ct-programs">
                <div class="cf-header mb-5 paleMainColor">{{ row.dTitle }}</div>
                <div class="ct-body">
                  <div class="ct-items">
                    <div class="ct-item" v-for="(pro, index) in programs" :key="index">
                      <div class="ct-title paleMainColor">{{ pro.title }}</div>
                      <div class="ct-type mb-4">{{ pro.subTitle }}</div>
                      <router-link class="read-more" 
                        :to="{name: 'program-detail-certificates', params:{slug: pro.slug}}">
                        <div class="d-flex align-items-center">
                          Read More
                          <span class="icon-back"></span>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="ct-badge text-center">
                   <!--  <div class="ct-badge-view"> -->
                      <img v-if="" :src="row.image3" style="height:350px">
                     <!--  <span class="icon-quality icon"></span>
                      <div class="badge-title">
                        Become
                        <br />Certified
                        <br />Today
                      </div> -->
                    <!-- </div> -->
                  </div>
                </div>
              </div>

              <hr class="mb-80" />

              <div id="certificate-authentications" class="cw-article">
                <div v-if="row.image5_1" class="cw-image">
                  <img :src="row.image5_1" alt />
                </div>
                <div v-if="row.cBody1" class="cw-details">
                  <div class="text">
                    <div v-html="row.cBody1"></div>
                  </div>
                </div>

                
                <div v-if="row.cBody2" class="cw-details">
                  <div class="text">
                    <div v-html="row.cBody2"></div>
                  </div>
                </div>
                <div v-if="row.image5_2" class="cw-image">
                  <img :src="row.image5_2" alt />
                </div>


                <div v-if="row.image5_3" class="cw-image">
                  <img :src="row.image5_3" alt />
                </div>
                <div v-if="row.cBody3" class="cw-details">
                  <div class="text">
                    <div v-html="row.cBody3"></div>
                  </div>
                </div>

                <div v-if="row.cBody4" class="cw-details">
                  <div class="text">
                    <div v-html="row.cBody4"></div>
                  </div>
                </div>
                <div v-if="row.image5_4" class="cw-image">
                  <img :src="row.image5_4" alt />
                </div>

              </div>

            </div>
         
            <!-- Right SideBar -->
            <div v-if="pgLoading" class="accrediation-became">
              <div class="webkit-animation webkit-100-95"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
            </div>
            <div v-if="!pgLoading" class="accrediation-became">

            <!-- Download -->
            <p v-if="row.has_download">
              <div class="program-download" v-if="row.has_download">
                <div class="download-catalog">
                    <img v-if="row.download_image" 
                        :src="row.download_image" 
                        :alt="row.download_name" 
                        class="img-fluid" />
                    <div class="title bg-title" 
                        style="text-transform: unset !important" 
                        v-html="row.download_name">
                    </div>
                    <a v-if="row.download_file" 
                        :href="row.download_file" 
                        target="_blank" 
                        download class="download-btn">Download
                    </a>
                </div>
              </div>
            </p>

            
            <p v-if="row.has_application" class="webkit-mt-2">
              <div v-if="row.has_application">
                  <div class="title bg-title mb-3" 
                      style="text-transform: unset !important"
                      v-html="row.application_name">
                  </div>

                  <router-link 
                    v-if="row.application_path == 'accreditation-applications' || 
                          row.application_path == 'certificate-applications'"
                    :to="'/online-applications/v2/'+row.application_path+'?reefer=Certificate'"
                    class="read-more">
                    <div>Apply Now <span class="icon-back"></span></div>
                  </router-link>
                  <router-link v-else
                    :to="'/online-applications/'+row.application_path+'?reefer=Certificate'"
                    class="read-more">
                    <div>Apply Now <span class="icon-back"></span></div>
                  </router-link>

              </div>
            </p>

            <!-- FAQ -->
            <p v-if="row.has_faq" class="webkit-mt-2">
              <div class="accred-faq" v-if="row.has_faq">
                <router-link v-if="row.faq_link" :to="row.faq_link" class="faq-link d-flex mb-30">
                  <div class="faq-bold d-flex align-items-center">FAQ</div>
                  <div class="faq-small d-flex align-items-center">
                      Frequently Asked<br />Questions
                  </div>
                </router-link>
              </div>
            </p>


            <p class="webkit-mt-2">
                <div class="training" v-if="row.has_payment">
                  <a v-if="row.payment_link" :href="row.payment_link"
                      target="_blank" 
                      class="read-more"
                      style="border:1px solid #1b1464">
                      <div>{{ row.payment_name }} </div>
                  </a>
                </div>
            </p>

            </div>
            <!-- End Right Sidebar -->

        </div>
      </div>
     </div>
    </div>
    <!-- Content -->

    <Footer />
  </div>
</template>

<style scoped src="../../components/common/css/NewsCP.css"></style>
<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}

.program-content {
  width: calc(100% - 500px);
}
/* Max Width 768px(767.98px) */
@media (max-width: 767.98px) {
  .program-content {
    width: 100%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .accrediation-became {
    display: none;
  }
  .program-content {
    width: calc(100% - 210px);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-content {
    width: calc(100% - 500px);
  }
}

.title-line:after {
  left: 106%;
}
.req-bullet {
  font-size: 16px;
  color: #585858;
  position: relative;
  padding-left: 20px;
  margin-bottom: 30px;
}
.req-bullet:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  border-radius: 50%;
  border: 1px solid #4f17a8;
  width: 8px;
  height: 8px;
}
.requirements-view {
  display: flex;
  justify-content: space-between;
}
.requirements-view > div {
  width: 48%;
}
.requirements-details {
  padding: 30px 0;
}
.program-title {
  font-weight: 600;
  font-size: 23px;
  color: #2b0962;
}
.beneficiaries-list {
  display: flex;
  justify-content: space-between;
}
.beneficiaries-list > div {
  width: 48%;
}
.corporate-widget {
  display: flex;
  justify-content: space-between;
}
.corporate-widget > div {
  width: 50%;
}
.corporate-widget-details {
  background-color: #0101a3;
  padding: 70px 50px;
}
.corporate-widget-details .type,
.corporate-widget-details .title {
  color: #d9e362;
}
.type {
  font-size: 20px;
  position: relative;
  margin-bottom: 40px;
}
.type:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 50px;
  background-color: #d9e362;
  height: 4px;
}
.corporate-widget-details .title {
  font-weight: bold;
  font-size: 40px;
  font-size: 37px;
  line-height: 1;
}
.proof-bullets {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.proof-bullets > div {
  width: 48%;
}
.membership-attainment-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membership-attainment-steps > div {
  width: 48%;
  color: #6e6d76;
  font-size: 17px;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    display: none;
  }
}
.read-more > div {
  font-size: 12px;
}
.title {
  line-height: 1.2;
  font-weight: bold;
  color: #2c3e50;
}
.bg-title {
  font-weight: bold;
  font-size: 20px;
  color: #1b1464;
}
.faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 33px;
  color: #1b1464;
}
</style>
<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import DownloadCatalog from "../../components/DownloadCatalog.vue";
import axios from "axios";

export default {
  name: "Certificate",
  components: {
    Navbar: Navbar,
    Footer: Footer,
    DownloadCatalog: DownloadCatalog,
  },
  data() {
    return {
      rows: [],
      //defCertsView: require("../../assets/img/Certificate-of-Achievement.png"),
      //verticalCertsView: require("../../assets/img/Certificate-of-Diploma.png"),
      defCertsView: '',
      verticalCertsView: '',

      navigation: '',
      defActiveItem: null,
      pdActiveItem: null,
      defCerPdf: null,
      proCerPdf: '',
      
      certificates1: [],
      certificates2: [],
      certificates3: [],

      row: '',
      programs: [],
      pgLoading: true,
    };
  },
  created() {
    this.fetchRow();
  },
  methods: {
    fetchRow() {
      axios
        .get(window.baseURL + "/our-certificates")
        .then((res) => {
          console.log(res.data.rows);
          this.pgLoading = false;
          this.row = res.data.rows;
          this.programs = res.data.rows.programs;
          this.navigation = res.data.navigation;
          this.certificates1 = res.data.rows.certificates_1;
            this.defCertsView = res.data.rows.certificates_1[0].image;
            this.defActiveItem = res.data.rows.certificates_1[0].id;
          this.certificates2 = res.data.rows.certificates_2;
            this.verticalCertsView = res.data.rows.certificates_2[0].image;
            this.pdActiveItem = res.data.rows.certificates_2[0].id;
          this.certificates3 = res.data.rows.certificates_3;
        })
        .catch(() => {});
    },

    toggleDefCerts(data, defCerPdf, id) {
      this.defCertsView = data;
      this.defCerPdf = defCerPdf;
      this.defActiveItem = id;
    },
    toggleProCerts(data, proCerPdf, id) {
      this.verticalCertsView = data;
      this.proCerPdf = proCerPdf;
      this.pdActiveItem = id;
    },
    toggleProDipCerts(data, proCerPdf, id) {
      this.verticalCertsView = data;
      this.proCerPdf = proCerPdf;
      this.pdActiveItem = id;
    },
  },
};
</script>
