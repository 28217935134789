<template>
  <div class="vp-container">
    <div class="container">
      <div class="vp-row">
        <div class="vp-card">
          <div class="bg-title mb-4" v-html="row.title1" :style="{color: '#274379'}"></div>
          <div class="text mb-5" v-html="row.body1" :style="{color: '#6F6F6F'}"></div>
          <router-link v-if="row.redirect1" :to="row.redirect1" class="nml-btn">
            <div class="d-flex align-items-center">
              <span class="nml-title" v-html="row.button1"></span>
              <span class="icon-arrow-right icon"></span>
            </div>
          </router-link>
        </div>

        <div class="vp-card">
          <iframe class="vp-iframe" :src="row.video_preview" frameborder="0"></iframe>
          <!-- https://www.youtube.com/embed/Qxb81znCkes -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.vp-container {
  
}
.vp-row {
  display: flex;
  flex-wrap: wrap;
  padding: 90px 0;
  border-bottom: 1px solid #4F669224;
}
.vp-card {
  width: 50%;
}
.bg-title {
  font-size: 44px;
}
.text {
  font-size: 18px;
  line-height: 1.4;
}
.nml-btn * {
  color: #274379;
}
.vp-iframe {
  width: 100%;
  height: 300px;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    
}

@media (max-width: 767.98px) {
  .vp-card {
    width: 100%;
    margin-bottom: 30px;
  }
  .vp-card:last-of-type {
    margin-bottom: 0;
  }
  .text {
    margin-bottom: 2rem !important;
  }
  .vp-iframe {
    height: 250px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .bg-title {
    font-size: 33px;
  }
  .text {
    font-size: 16px;
  }
  .vp-card:first-of-type {
    padding-right: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
</style>

<script>
export default {
  name: "Section1",
  props: ['row'],
  components: {},
  data() {
    return {
      pgLoading: true,
    };
  },
  created() {
      //
  },
  methods: {
    //
  },
};
</script>
