import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // My Routes

  // Auth
  {
    path: "/register",
    name: "register",
    component: require("../views/Auth/Register.vue").default,
  },
  {
    path: "/verify",
    name: "verify",
    component: require("../views/Auth/Verify.vue").default,
  },
  {
    path: "/reset-password",
    name: "reset",
    component: require("../views/Auth/Reset.vue").default,
  },
  {
    path: "/forgot-password",
    name: "forgot",
    component: require("../views/Auth/Forget.vue").default,
  },
  {
    path: "/login",
    name: "login",
    component: require("../views/Auth/Login.vue").default,
  },
  {
    path: "/logout",
    name: "logout",
    component: require("../views/Auth/Logout.vue").default,
  },
  {
    path: "/me/profile",
    name: "myProfile",
    component: require("../views/Auth/Profile.vue").default,
  },
  {
    path: "/me/certificate",
    name: "myCertificate",
    component: require("../views/Auth/MyCertificate.vue").default,
  },
  {
    path: "/me/certificate-achievement",
    name: "myCertificateAchievement",
    component: require("../views/Auth/MyAchievement.vue").default,
  },

  // Home
  {
    path: "/",
    name: "home",
    component: require("../views/Home/List.vue").default,
  },
  {
    path: "/search",
    name: "search",
    component: require("../components/Search.vue").default,
  },

  // Popular Search
  {
    path: "/popular-search/:slug",
    name: "show-popular-search",
    component: require("../views/PopularSearchs/Show.vue").default,
  },
  {
    path: "/popular-search/shortcut/:slug",
    name: "shortcut-popular-search",
    component: require("../views/PopularSearchs/Shortcut.vue").default,
  },
  {
    path: "/popular-search/in/program/:slug",
    name: "program-popular-search",
    component: require("../views/PopularSearchs/ShowProgram.vue").default,
  },

  // Accreditations
  {
    path: "/accreditations",
    name: "accreditations",
    component: require("../views/Accreditations/Show.vue").default,
  },
  {
    path: "/accreditations/:slug",
    name: "show-accreditations",
    component: require("../views/Accreditations/Show.vue").default,
  },

  // Programs
  {
    path: "/programs",
    name: "programs",
    component: require("../views/Programs/List.vue").default,
  },
  {
    path: "/programs/sectors/:slug",
    name: "sector-programs",
    component: require("../views/Programs/Sector.vue").default,
  },
  {
    path: "/programs/sectors/in/:slug",
    name: "program-detail-programs",
    component: require("../views/Programs/ShowProgram.vue").default,
  },

  {
    path: "/online-applications/:slug",
    name: "online-applications",
    component: require("../views/Programs/Application.vue").default,
  },
  {
    path: "/online-applications/v2/:slug",
    name: "online-applications-v2",
    component: require("../views/Accreditations/Application.vue").default,
  },
  // Certifications
  {
    path: "/our-certificates",
    name: "certificates",
    component: require("../views/Certificate/List.vue").default,
  },
  {
    path: "/certificates/program/:slug",
    name: "program-detail-certificates",
    component: require("../views/Certificate/ShowProgram.vue").default,
  },

  // Membership
  {
    path: "/memberships",
    name: "memberships",
    component: require("../views/Memberships/List.vue").default,
  },
  {
    path: "/memberships/:slug",
    name: "show-memberships",
    component: require("../views/Memberships/Show.vue").default,
  },

  // About
  {
    path: "/about",
    name: "about",
    component: require("../views/About/Show.vue").default,
  },

  // Our Partners
  {
    path: "/our-partners",
    name: "our-partners",
    component: require("../views/OurPartners/List.vue").default,
  },
  {
    path: "/our-partners/:slug",
    name: "show-our-partners",
    component: require("../views/OurPartners/Show.vue").default,
  },

  // Our Services
  {
    path: "/our-services",
    name: "our-services",
    component: require("../views/OurServices/Show.vue").default,
  },
  {
    path: "/our-services/:slug",
    name: "show-services",
    component: require("../views/OurServices/Show.vue").default,
  },

  // Contact Us
  {
    path: "/contact",
    name: "contact",
    component: require("../views/Contacts/List.vue").default,
  },

  // Pages
  {
    path: "/page/:slug",
    name: "show-page",
    component: require("../views/Pages/Show.vue").default,
  },

  // Privacy
  {
    path: "/privacy",
    name: "privacy",
    component: require("../views/Privacy/List.vue").default,
  },

  // FAQS
  {
    path: "/faq",
    name: "faq",
    component: require("../views/Faq/List.vue").default,
  },

  // Events
  {
    path: "/events",
    name: "events",
    component: require("../views/Events/Show.vue").default,
  },

  // Online Trainings
  {
    path: "/online-trainings",
    name: "online-trainings",
    component: require("../views/OnlineTrainings/Show.vue").default,
  },
  {
    path: "/online-trainings/:slug",
    name: "show-online-trainings",
    component: require("../views/OnlineTrainings/Show.vue").default,
  },

  {
    path: "/*",
    name: "not-found",
    component: require("../components/NotFound-404.vue").default,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  fallback: true,
  routes,
});

export default router;
