<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <!-- Background -->
        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${row.bgColor} !important`}">
            <div class="align-center">
              <div class="title" v-html="row.bgTitle"></div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${row.image})`}"></div>
        </div>
        <!-- End Background -->


        <div class="program-container">
          <div class="program-row">

            <div v-if="pgLoading" class="events-container" style="width: 100%">
              <div class="events-row mb-70">
                <div class="row" style="margin-left: unset">
                    <div class="col-lg-4 col-sm-6 webkit-animation webkit-100-125"
                      style="border: 10px solid #fff;height:300px">
                      <div class="events-box"></div>
                    </div>
                    <div class="col-lg-4 col-sm-6 webkit-animation webkit-100-125"
                      style="border: 10px solid #fff;height:300px">
                      <div class="events-box"></div>
                    </div>
                    <div class="col-lg-4 col-sm-6 webkit-animation webkit-100-125"
                      style="border: 10px solid #fff;height:300px">
                      <div class="events-box"></div>
                    </div>
                </div>
              </div>
            </div>

            <div v-if="!pgLoading" class="events-container" style="width: 100%">
              <div class="events-row mb-70">
                <div class="row">

                  <div v-if="row.body1" class="col-lg-4 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body1"></div>
                    </div>
                  </div>
                  <div v-if="row.body2" class="col-lg-4 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body2"></div>
                    </div>
                  </div>
                   <div v-if="row.body3" class="col-lg-4 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body3"></div>
                    </div>
                  </div>

                  <div v-if="row.body4" class="col-lg-4 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body4"></div>
                    </div>
                  </div>
                  <div v-if="row.body5" class="col-lg-4 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body5"></div>
                    </div>
                  </div>
                   <div v-if="row.body6" class="col-lg-4 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body6"></div>
                    </div>
                  </div>


                </div>
              </div>

              
              <div v-if="row.background" class="events-background mb-70"
                :style="{backgroundImage:`url(${row.background})`}">
                <div class="event-title" v-html="row.bgHint"></div>
              </div>

              <div class="events-types">

                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body7"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body8"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body9"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body10"></div>
                    </div>
                  </div>
                </div>

                <hr v-if="row.body11 || row.body12 || row.body13 || row.body14" 
                    style="padding-bottom:40px;margin-bottom: 60px">

                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body11"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body12"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body13"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body14"></div>
                    </div>
                  </div>
                </div>

                 <hr v-if="row.body15 || row.body16 || row.body17 || row.body18" 
                    style="padding-bottom:40px;margin-bottom: 60px">

                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body15"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body16"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body17"></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="events-box">
                      <div class="description-about" v-html="row.body18"></div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Content -->

    <Footer />
  </div>
</template>

<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}
  .events-box {
    margin-bottom: 50px;
    padding-right: 60px;
  }
  .event-title-bg {
    font-weight: bold;
    font-size: 23px;
  }
  .events-background {
    padding: 130px 60px 50px;
    background-size: cover;
    background-position: center;
  }
  .event-title {
    color: #fff;
    font-weight: bold;
    font-size: 50px;
    line-height: 1;
  }
  .md-title {
    font-weight: 600;
    font-size: 17px;
  }
  .events-types {
    width: 70%;
    margin-left: auto;
  }

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
  }

  @media (max-width: 767.98px) {
    .events-background {
      padding: 60px 50px;
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
    }
    .event-title {
      font-size: 30px;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    
  }

  @media (max-width: 991.98px) {
    .events-types {
      width: 100%;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
  
   
   
  }

  @media (max-width: 1199.98px) {
    .events-box {
      padding-right: 0;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
</style>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import axios from "axios";
export default {
  name: "Events",
  components: {
    Navbar: Navbar,
    Footer: Footer,
  },
  data() {
    return {
      row: '',
      pgLoading: true
    }
  },
  created() {
    this.fetchRow();
  },
  methods: {
      
    fetchRow() {
      this.pgLoading = true;
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL+'/events',
        method: 'GET',
        data: {},
        params: {},
      }
      axios(options)
        .then(res => {
          this.pgLoading = false;
          this.row = res.data.row;
        })
        .catch(() => {})
        .finally(() => {})
    },

  },
};
</script>
