<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${rows[0].bgColor} !important`}">
            <div class="align-center">
              <div class="faq-title">
                <div v-html="rows[0].bgTitle"></div>
              </div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${rows[0].image})`}"></div>
          <!-- <div class="program-header-view" :style="{backgroundImage:`url(${require('../../assets/img/tc.png')})`}"></div> -->
        </div>

        
        <div class="program-container">
          <div class="program-row">


            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
              <div class="sidebar-link">
                <div class="link webkit-animation webkit-100-50"></div>
              </div>
            </div>

            <div v-if="!pgLoading" class="program-sidebar">
              <div class="sidebar-link" v-for="(nav, index) in navigation" :key="index">
                  <a :href="'#'+nav.slug" class="link">
                    {{ nav.title }}
                  </a>
              </div>
            </div>

            <div v-if="pgLoading" class="program-content program-content-about">
              <div class="program-title webkit-animation webkit-50-50"></div>
              <div class="description-about webkit-animation webkit-100-440"></div>
            </div>

            <div v-if="!pgLoading" class="program-content program-content-about">

              <div v-for="(row, index) in rows" :key="index" :id="row.slug">
              
              <div class="description-about" v-if="row.body1" v-html="row.body1"></div>

              <div class="program-articles-rows" 
                v-if="row.title2_1 || row.body2_1 || row.video2_2 ||
                      row.title3_1 || row.body3_1 || row.title3_2 || row.body3_2 ||
                      row.title4_1 || row.body4_1 || row.body4_2 ||
                      row.title5_1 || row.body5_1 || row.body5_2 || row.body5_3 ||
                      row.body5_4 || row.body5_5 || row.body5_6 || row.body5_7 || row.body5_8">

                <div class="program-about-article" v-if="row.title2_1 || row.body2_1 || row.video2_2">
                  <div class="about-article-box">
                    <div class="program-about-article-title" v-html="row.title2_1"></div>
                    <div class="program-about-article-text" v-html="row.body2_1"></div>
                  </div>

                  <div class="about-article-box">
                    <div class="about-article-box-media mt-5" style="height: 260px">
                      <iframe v-if="row.video2_2" :src="row.video2_2" style="width: 100%;height: 280px"></iframe>
                    </div>
                  </div>
                </div>

                <div class="program-about-article" 
                  v-if="row.title3_1 || row.body3_1 || row.title3_2 || row.body3_2">
                  <div class="about-article-box">
                    <div class="program-about-article-title" v-html="row.title3_1"></div>
                    <div class="program-about-article-text" v-html="row.body3_1"></div>
                  </div>

                  <div class="about-article-box">
                    <div class="program-about-article-title" v-html="row.title3_2"></div>
                    <div class="program-about-article-text" v-html="row.body3_2"></div>
                  </div>
                </div>

                <div class="program-about-article" 
                  v-if="row.title4_1 || row.body4_1 || row.body4_2">
                  <div class="about-article-box">
                    <div class="program-about-article-title" v-html="row.title4_1"></div>
                    <div class="program-about-article-text" style='margin-left: 20px' v-html="row.body4_1"></div>
                  </div>

                  <div class="about-article-box d-flex align-items-center">
                    <div class="program-about-article-text mt-4" style='margin-left: 20px' v-html="row.body4_2"></div>
                  </div>
                </div>

                <div class="program-about-article" 
                  v-if="row.title5_1 || row.body5_1 || row.body5_2 || row.body5_3 ||
                        row.body5_4 || row.body5_5 || row.body5_6 || row.body5_7 || row.body5_8">
                  <div class="aab">
                    <div class="program-about-article-title mb-5" v-html="row.title5_1"></div>
                    <div class="about-arts">

                      <div v-if="row.body5_1" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_1"></div>
                          <router-link v-if="row.read5_1" :to="row.read5_1" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_1" :src="row.image5_1" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_2" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_2"></div>
                          <router-link v-if="row.read5_2" :to="row.read5_2" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_2" :src="row.image5_2" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_3" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_3"></div>
                          <router-link v-if="row.read5_3" :to="row.read5_3" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_3" :src="row.image5_3" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_4" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_4"></div>
                          <router-link v-if="row.read5_4" :to="row.read5_4" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_4" :src="row.image5_4" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_5" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_5"></div>
                          <router-link v-if="row.read5_5" :to="row.read5_5" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_5" :src="row.image5_5" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_6" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_6"></div>
                          <router-link v-if="row.read5_6" :to="row.read5_6" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_6" :src="row.image5_6" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_7" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_7"></div>
                          <router-link v-if="row.read5_7" :to="row.read5_7" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_7" :src="row.image5_7" class="img-fluid" alt />
                        </div>
                      </div>

                      <div v-if="row.body5_8" class="aa-row">
                        <div class="details">
                          <div class="description mb-40" v-html="row.body5_8"></div>
                          <router-link v-if="row.read5_8" :to="row.read5_8" class="read-more">
                            <div>
                              Read More
                              <span class="icon-back"></span>
                            </div>
                          </router-link>
                        </div>
                        <div class="view">
                          <img v-if="row.image5_8" :src="row.image5_8" class="img-fluid" alt />
                        </div>
                      </div>

                      

                    </div>
                  </div>
                </div>
              </div>
             </div>
            </div>

            <!-- Right SideBar -->
            <div v-if="pgLoading" class="accrediation-became">
              <div class="webkit-animation webkit-100-95"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
            </div>
            <div v-if="!pgLoading" class="accrediation-became">

              <p>
            <div class="became-a" v-if="rows[0].has_application" style="margin-left: 20px">
                <div class="title mb-3" style="text-transform: unset !important;font-size: 20px;color: #1b1464;" v-html="rows[0].application_name"></div>

                  <router-link 
                    v-if="rows[0].application_path == 'accreditation-applications' || 
                          rows[0].application_path == 'certificate-applications'"
                    :to="'/online-applications/v2/'+rows[0].application_path+'?reefer=Membership'"
                    class="read-more">
                    <div>Apply Now <span class="icon-back"></span></div>
                  </router-link>
                  <router-link v-else
                    :to="'/online-applications/'+rows[0].application_path+'?reefer=Membership'"
                    class="read-more">
                    <div>Apply Now <span class="icon-back"></span></div>
                  </router-link>

            </div>
            </p>

              <!-- Download -->
              <p>
                <div class="program-download" v-if="rows[0].has_download">
                  <div class="download-catalog">
                      <img v-if="rows[0].download_image" 
                          :src="rows[0].download_image" 
                          :alt="rows[0].download_name"
                          class="img-fluid" />
                      <div class="title" style="font-size: 20px;color: #1b1464;text-transform: unset !important">
                          {{ rows[0].download_name }}
                      </div>
                      <a v-if="rows[0].download_file"
                          :href="rows[0].download_file"
                          target="_blank"
                          download class="download-btn">Download
                      </a>
                  </div>
                </div>
              </p>
              <!-- End Download -->

              <!-- Application -->
              
              <!-- End Application -->

              <!-- FAQ -->
              <p class="webkit-mt-2">
                <div class="accred-faq" v-if="rows[0].has_faq">
                    <router-link v-if="rows[0].faq_link" :to="rows[0].faq_link" class="faq-link d-flex mb-30">
                        <div class="faq-bold d-flex align-items-center">FAQ</div>
                        <div class="faq-small d-flex align-items-center">
                            Frequently Asked<br />Questions
                        </div>
                    </router-link>
                </div>
              </p>
              <!-- End FAQ -->

              <p class="webkit-mt-2">
                <div class="training" v-if="rows[0].has_payment">
                  <a v-if="rows[0].payment_link" :href="rows[0].payment_link"
                      target="_blank" 
                      class="read-more"
                      style="border:1px solid #1b1464">
                      <div>{{ rows[0].payment_name }} </div>
                  </a>
                </div>
            </p>


            </div>
            <!-- End Right Sidebar -->

          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>

<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .corporate-widget > div {
    width: 100%;
  }
}

  .title-line:after {
    background-color: #4bb3ff !important;
  }

  /* About Program */
  .about-title {
    font-size: 25px;
  }
  .program-articles-rows {
    /*padding-top: 60px;*/
  }

  .description-about { margin-bottom: 70px }

  .program-about-article {
    border-top: 1px solid #00000016;
    padding-top: 27px;
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;
  }
  .program-about-article .small-title,
  .program-about-article .medium-title {
    font-weight: bold;
    font-size: 17px;
  }
  .program-about-article .medium-title {
    font-size: 23px;
    color: #1b1464;
    margin-bottom: 8px;
  }
  .aa-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding-bottom: 50px;
    /*border-bottom: 1px solid #00000016;*/
    flex-wrap: wrap;
  }
  .about-article-box {
    width: 48%;
  }
  .aa-row:last-of-type {
    padding-bottom: 0;
    border: none;
  }
  .aa-row .details {
    width: calc(100% - 400px);
  }
  .aa-row .view {
    width: 320px;
    height: 185px;
  }
  .aa-row .view img {
    width: 100%;
    height: 100%;
  }
  .about-article-box-media {
    position: relative;
  }
  .about-article-box-media video,
  .about-article-box-media img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .about-article-box-media .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-article-box-media .overlay .icon-seek {
    color: #fff;
    font-size: 50px;
  }

  .program-about-article-title {
    position: relative;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 15px;
    color: #1b1464;
    display: inline-block;
  }
  .program-about-article-title:after {
    content: "";
    position: absolute;
    top: -28.5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #4f17a8;
  }

  .program-about-article-text {
    color: #6e6d76;
    font-size: 18px;
  }

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .program-about-article {
      display: block;
    }
    .about-article-box {
      width: 100%;
      margin-bottom: 60px;
    }
    div.aa-row {
      flex-direction: column-reverse;
      padding-top: 0;
    }
    div.aa-row > div {
      width: 100% !important;
    }
    div.aa-row .view {
      margin-bottom: 15px;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 576px) and (max-width: 1199.98px) {
    .aa-row .view {
      width: 270px;
    }
    .aa-row .details {
      width: calc(100% - 300px);
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }

  .faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 33px;
  color: #1b1464;
}
</style>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import DownloadCatalog from "../../components/DownloadCatalog.vue";
import axios from "axios";
export default {
  name: "About",
  components: {
    Navbar: Navbar,
    Footer: Footer,
    DownloadCatalog: DownloadCatalog,
  },
  data() {
    return {
      slug: '',
      rows: [],
      navigation: [],
      pgLoading: true,
    };
  },
  computed: {
  },
  created() {
    if(this.$route.params.slug) {
      this.slug = this.$route.params.slug;
    }
    this.fetchRow();
  },
  methods: {
    
    fetchRow() {
      this.pgLoading = true;
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
      };
      //let baseURL = ;
      // if(this.slug) {
      //   baseURL = window.baseURL+'/about/'+this.slug;
      // }
      const options = {
        url: window.baseURL+'/about',
        method: 'GET',
        data: {},
        params: {},
      }
      axios(options)
        .then(res => {
          this.pgLoading = false;
          this.rows = res.data.rows;
          this.navigation = res.data.navigation;
        })
        .catch(() => {})
        .finally(() => {})
    },


  },
};
</script>
