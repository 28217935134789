<template>
  <div class="inp-container text-center">
    <div class="container">
      <div class="se-content">
        <div class="bg-title mb-2" v-html="row.title2"></div>
        <img :src="row.image2" class="img-fluid" alt="" />
        <div class="text" v-html="row.body2"></div>
        <router-link v-if="row.redirect2" :to="row.redirect2" class="nml-btn">
          <div class="d-flex align-items-center">
            <span class="nml-title" v-html="row.button2"></span>
            <span class="icon-arrow-right icon"></span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.inp-container {
  padding: 70px 0;
}
.text {
  color: #6F6F6F;
  line-height: 1.4;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}
.bg-title {
  font-size: 44px;
}
.nml-btn * {
  color: #6F6F6F;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  
}

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
  .inpro-view {
    background-position: center;
    height: 230px !important;
  }
  .inp-card > div {
    height: auto
  }
  .ins-header {
    padding: 40px 60px;
  }
  .ins-view, .ins-details {
    padding: 50px;
  }
  .bg-title {
    font-size: 36px;
    margin-bottom: 15px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .text {
    font-size: 17px;
    margin-bottom: 17px;
  }
  .bg-title {
    font-size: 40px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
</style>

<script>
export default {
  name: "Section2",
  props: ['row'],
  components: {},
  data() {
    return {
      pgLoading: true,
    };
  },
  created() {
      //
  },
  methods: {
    //
  },
};
</script>
