<template>
  <div class="page-container">
    <AuthHeader />
    <div class="navbar-auth-sm">
      <Navbar />
    </div>
    <div class="auth center-vertically">
      <div class="auth-content">
        <div class="title">Reset Password</div>
        <div class="auth-box">
          <div v-if="errors.length" class="alert alert-danger text-left">{{ errors }}</div>
          <div class="input-group mb-4">
            <input type="text" class="form-control" placeholder="Code" v-model="user.code" />
          </div>
          <div class="input-group mb-4">
            <input type="password" class="form-control" autocomplete="off" placeholder="New Password" v-model="user.password" />
          </div>
          <div class="input-group mb-4">
            <input type="password" class="form-control" autocomplete="off" placeholder="Confirm New Password" v-model="user.new_password" />
          </div>
          <button class="btn main-btn-backdrop" type="button" @click="send">
            <img src="../../assets/loader.svg" class="sm-loader" alt v-if="btnLoading" />
            <span v-else>Send</span>
          </button>
          <div class="dont-have-text pt-2">
            <router-link to="/login">Have an Account?</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="../../components/common/css/Auth.css">
</style>

<script>
import Search from "@/components/Search.vue";
import Navbar from "@/components/Navbar.vue";
import AuthHeader from "./AuthHeader.vue";
import axios from "axios";

export default {
  name: "ResetPassword",
  components: {
    Search,
    Navbar,
    AuthHeader
  },
  data() {
    return {
      user: {
        email: '',
        code: '',
        password: "",
        new_password: "",
      },
      btnLoading: false,
      errors: "",
    };
  },
  methods: {
    send() {
        this.btnLoading = true;
        axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        };
        const options = {
          url: window.baseURL + "/auth/password/reset",
          method: "POST",
          data: {
            email: this.user.email,
            token: this.user.code,
            new_password: this.user.password,
            new_password_confirmation: this.user.new_password,
          },
        };
        axios(options)
          .then((res) => {
            this.btnLoading = false;
            this.$router.push({ name: "login" });
          })
          .catch((err) => {
            this.btnLoading = false;
            if (err.response && err.response.status == 401) {
              this.errors = err.response.data.error;
            } else {
              this.errors = err.response ? err.response.data.message : "" + err;
            }
          });
    },
  },

  created() {
    if (localStorage.getItem('access_token')) {
      this.$router.push({ name: 'home' });
    }

    if (localStorage.getItem("forget_email")) {
       this.user.email = localStorage.getItem("forget_email");
    }
  },
};
</script>
