<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">
        
        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${bgColor} !important`}">
            <div class="align-center">
              <div class="title" v-html="bgTitle"></div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${imageHeader})`}"></div>
        </div>

        <div class="program-container">
          <div class="program-row">


            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="webkit-animation webkit-100-50"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
              </div>
            </div>
            <div v-if="!pgLoading" class="program-sidebar">
              <div class="sidebar-link" v-for="(link, index) in sidebarLinks" :key="index">
                <a :href="'#'+link.slug" class="link" v-html="link.title" :style="{borderBottom: !link.title ? 'none' : ''}"></a>
              </div>
            </div>


            <div v-if="pgLoading" class="program-content">
              <div class="webkit-animation webkit-50-50"></div>
              <div class="webkit-animation webkit-100-250 webkit-mt-1"></div>  
            </div>

            <div v-if="!pgLoading" class="program-content">
              <!-- <div class="program-title">
                {{ overview.title }}
              </div> -->
              <div id="overview" class="description-about" v-html="overview.body"></div>

              <div :id="row1.slug" class="program-sectors">
                <div class="sectors-title" v-html="row1.title"></div>
                <!-- dynamic Only -->
                <div class="row">
                  <div class="col-lg-4 col-sm-6" v-for="(sector, index) in sectors" :key="index">
                    <div class="program-sector-card">
                      <img
                        v-if="sector.image"
                        :src="sector.image"
                        class="program-sector-card-view width-fluid" style='height: 150px'
                        alt="Bussines"
                      />
                      <img
                        v-else
                        :src="require('../../assets/img/sector.jpg')"
                        class="program-sector-card-view width-fluid"
                        alt="Bussines"
                      />
                      <div class="program-sector-card-title" v-html="sector.title"></div>
                      <div>
                        <a
                          :href="sector.redirect"
                          target="_blank"
                          class="read-more">
                          <div class="d-flex align-items-center">
                            {{ sector.button ? sector.button : 'Read More' }}
                            <span class="icon-back"></span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


  
                <div class="program-certification-widget" 
                    v-for="(app, idx) in sidebarLinks" 
                    :key="idx"
                    :id="app.slug"
                    v-if="idx >= 2"
                    :style="(app.imgDir == 'right') ? '' :'flex-direction: row-reverse' ">

                      <div class="program-certification-details"
                        :style="{backgroundColor:`${app.bgColor} !important`}">
                        <div class="text" v-html="app.bgTitle"></div>
                        <router-link v-if="app.link" :to="app.link" class="read-more white-btn mt-5">
                          <div>Learn More
                            <span class="icon-back"></span>
                          </div>
                        </router-link>
                      </div>
                      <div class="program-certification-view"
                        :style="{backgroundImage:`url(${app.image}) !important`} ">
                      </div>
                  
                  <div class="col-12 description-about" 
                    style="margin-top: 10px" 
                    v-if="app.body" v-html="app.body">
                  </div>
                </div>



              </div>
            </div>

           <!--  <DownloadCatalog /> -->
            <div class="program-download">
              <p v-if="row.has_download">
                <div v-if="row.has_download">
                  <div class="download-catalog">
                      <img v-if="row.download_image" 
                          :src="row.download_image" 
                          :alt="row.download_name" 
                          class="img-fluid" />
                      <div class="title bg-title" 
                          style="text-transform: unset !important" 
                          v-html="row.download_name">
                      </div>
                      <a v-if="row.download_file" 
                          :href="row.download_file" 
                          target="_blank" 
                          download class="download-btn">Download
                      </a>
                  </div>
                </div>
              </p>

              <p v-if="row.has_faq" class="webkit-mt-2">
                <div class="accred-faq">
                  <router-link v-if="row.faq_link" :to="row.faq_link" class="faq-link d-flex mb-30">
                    <div class="faq-bold d-flex align-items-center">FAQ</div>
                    <div class="faq-small d-flex align-items-center">
                        Frequently Asked<br />Questions
                    </div>
                  </router-link>
                </div>
              </p>

              <p class="webkit-mt-2" v-if="row.has_payment">
                  <div class="training">
                    <a v-if="row.payment_link" :href="row.payment_link"
                        target="_blank" 
                        class="read-more"
                        style="border:1px solid #1b1464">
                        <div>{{ row.payment_name }} </div>
                    </a>
                  </div>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>

<style>
.sidebar-link .link p {
  margin: 0;
}
.sidebar-link .link span {
  display: block;
  font-size: 16px !important;
  font-size: 17px;
  font-weight: bold;
  color: #6e6d76 !important;
  line-height: 1;
}
</style>

<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}

.program-sector-card-view {
  height: 130px;
}
.program-sector-card {
  padding: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .program-box-col {
    width: 100%;
  }
  .program-sector-card {
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .program-sector-card-title {
    padding: 20px 10px 20px 0;
    width: calc(100% - 115px);
  }
}

/* Max Width 768px(767.98px) */
@media (max-width: 767.98px) {
  .program-sidebar {
    display: none;
  }
  .program-certification-details {
    width: 100%;
  }
  .program-certification-widget {
    flex-direction: column-reverse;
  }
  .program-certification-view {
    /* display: none; */
    width: 100%;
    height: 250px;
  }
  .program-sector-card-view {
    height: auto;
  }
  .program-certification-details {
    padding: 30px;
  }
  .program-sector-card {
    margin-bottom: 25px;
  }
  .program-certification-details .read-more {
    margin-top: 20px !important;
  }
  .program-certification-widget .text {
    font-size: 38px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .program-certification-details {
    width: 100%;
  }
  .program-certification-view {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-certification-details {
    width: 100%;
  }
  .program-certification-view {
    display: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .program-sector-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .program-sector-card-title {
    padding: 20px 10px 20px 0;
    width: calc(100% - 115px);
  }
}

.bg-title {
  font-weight: bold;
  font-size: 18px;
  color: #1b1464;
}
.faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 28px;
  color: #1b1464;
}
</style>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import DownloadCatalog from "../../components/DownloadCatalog.vue";
import axios from "axios";

export default {
  name: "Programs",
  components: {
    Navbar: Navbar,
    Footer: Footer,
    DownloadCatalog: DownloadCatalog,
  },
  data() {
    return {
      sectors: [],
      headerTitle: "",
      overview: "",
      sidebarLinks: [],
      imageHeader: "",
      bgColor: '',
      bgTitle: '',
      row: [],
      row1: [],
      bgTitle3: '',
      bgColor3:'',
      imageHeader3: '',

      pgLoading: true,
    };
  },
  watch: {
    $route() {
      this.fetchRow();
    },
  },
  created() {
    this.fetchRow();
    

    // Check Auth
    // if (!localStorage.getItem("access_token")) {
    //   this.$router.push({ name: "Login" });
    // }
  },

  methods: {


    fetchRow(){
    axios
      .get(window.baseURL + "/programs")
      .then((res) => {
        this.pgLoading = false;
        console.log(res.data.rows);
        this.sidebarLinks = res.data.rows;
        const data = res.data.rows;
        this.imageHeader = data[0].image;
        this.bgColor = data[0].bgColor;
        this.bgTitle = data[0].bgTitle;
        this.row = data[0];
        this.row1 = data[1];
        this.imageHeader3 = data[2].image;
        this.bgColor3 = data[2].bgColor;
        this.bgTitle3 = data[2].bgTitle;

        // Get Sectors
        for (var i = 0; i < data.length; i++) {
          if (data[i].sectors != "") {
            this.sectors = data[i].sectors;
          }
        }

        console.log(this.sectors);

        // Get Overview Body
        this.overview = res.data.rows[0];
      })
      .catch(() => {
      });
    },


  },
};
</script>
