<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <!-- Background -->
        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>

        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${basic.bgColor} !important`}">
            <div class="align-center">
              <div class="title" v-html="basic.bgTitle"></div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${basic.image})`}"></div>
        </div>
        <!-- End Background -->

        <div class="program-container">
          <div class="program-row">

            <!-- Left Sidebar -->
            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="webkit-animation webkit-100-50"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
              </div>
            </div>
            <div v-if="!pgLoading" class="program-sidebar">
              <div class="sidebar-link" v-for="(nav, index) in navigation" :key="index">
                  <a :href="'#'+nav.slug" class="link">
                    {{ nav.title }}
                  </a>
              </div>
            </div>
            <!-- End Left Sidebar -->



            <!-- Content -->
            <div class="program-content">
              
              <div v-if="pgLoading">
                <div class="webkit-animation webkit-50-50"></div>
                <div class="webkit-animation webkit-100-250 webkit-mt-1"></div>
              </div>

              <div v-if="!pgLoading">

                <div v-for="(row, index) in rows" :key="index" :id="row.slug">

                  <!-- Group 1 -->
                  <div v-if="row.body1_1" class="description-about">
                    <div v-html="row.body1_1"></div>
                  </div>

                  <div v-if="basic.has_programs && index == 0" class="clearfix"><br/><br/><br/></div>
                  <!-- Programs -->
                  <div v-if="basic.has_programs && index == 0" class="certificate-types">
                    <router-link
                      class="certificate-type"
                      v-for="(pro, index) in basic.programs"
                      :key="index"
                      :to="{name: 'program-popular-search', params:{slug: pro.slug}}">
                      <div class="certificate-title" style="width: 100%">
                        {{ pro.title }}
                      </div>
                      <p><div class="certificate-text">{{ pro.subtitle }}</div></p>
                    </router-link>
                  </div>
                  <!-- Programs -->

                  
                  <div v-if="row.image1_2 || row.mask1_2 || row.body1_2_1 || row.body1_2_2"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-view" v-if="row.image1_2"
                      :style="{backgroundImage: `url(${row.image1_2})`}">
                    </div>
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color1_2} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line1_2} !important`}"></div>
                      <div class="title overline" v-html="row.mask1_2"></div>
                    </div>
                  </div>
                  <div class="row col-12" v-if="row.body1_2_1 || row.body1_2_2">
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_2_1" v-html="row.body1_2_1" style="margin-left: 20px"></div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_2_2" v-html="row.body1_2_2" style="margin-left: 20px"></div>
                    </div>
                  </div>


                  <div v-if="row.image1_3 || row.mask1_3 || row.body1_3_1 || row.body1_3_2"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color1_3} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line1_3} !important`}"></div>
                      <div class="title overline" v-html="row.mask1_3"></div>
                    </div>
                    <div class="benefits-widget-view" v-if="row.image1_3"
                      :style="{backgroundImage: `url(${row.image1_3})`}">
                    </div>
                  </div>
                  <div class="row col-12" v-if="row.body1_3_1 || row.body1_3_2">
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_3_1" v-html="row.body1_3_1" style="margin-left: 20px"></div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_3_2" v-html="row.body1_3_2" style="margin-left: 20px"></div>
                    </div>
                  </div>


                  <div v-if="row.image1_4 || row.mask1_4 || row.body1_4_1 || row.body1_4_2"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-view" v-if="row.image1_4"
                      :style="{backgroundImage: `url(${row.image1_4})`}">
                    </div>
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color1_4} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line1_4} !important`}"></div>
                      <div class="title overline" v-html="row.mask1_4"></div>
                    </div>
                  </div>
                  <div class="row col-12" v-if="row.body1_4_1 || row.body1_4_2">
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_4_1" v-html="row.body1_4_1" style="margin-left: 20px"></div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_4_2" v-html="row.body1_4_2" style="margin-left: 20px"></div>
                    </div>
                  </div>

                  <div v-if="row.image1_5 || row.mask1_5 || row.body1_5_1 || row.body1_5_2"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color1_5} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line1_5} !important`}"></div>
                      <div class="title overline" v-html="row.mask1_5"></div>
                    </div>
                    <div class="benefits-widget-view" v-if="row.image1_5"
                      :style="{backgroundImage: `url(${row.image1_5})`}">
                    </div>
                  </div>
                  <div class="row col-12" v-if="row.body1_5_1 || row.body1_5_2">
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_5_1" v-html="row.body1_5_1" style="margin-left: 20px"></div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div v-if="row.body1_5_2" v-html="row.body1_5_2" style="margin-left: 20px"></div>
                    </div>
                  </div>
                  <!-- End Group 1 -->



                  <!-- Group 2 -->
                  <div v-if="row.image2_1 || row.mask2_1 || row.body2_1"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-view" v-if="row.image2_1"
                      :style="{backgroundImage: `url(${row.image2_1})`}">
                    </div>
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color2_1} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line2_1} !important`}"></div>
                      <div class="title overline" v-html="row.mask2_1"></div>
                    </div>
                  </div>
                  <div class="col-12" v-if="row.body2_1">
                    <div v-if="row.body2_1" v-html="row.body2_1" style="margin-left: 20px"></div>
                  </div>

                  <div v-if="row.image2_2 || row.mask2_2 || row.body2_2"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color2_2} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line2_2} !important`}"></div>
                      <div class="title overline" v-html="row.mask2_2"></div>
                    </div>
                    <div class="benefits-widget-view" v-if="row.image2_2"
                      :style="{backgroundImage: `url(${row.image2_2})`}">
                    </div>
                  </div>
                  <div class="col-12" v-if="row.body2_2">
                    <div v-if="row.body2_2" v-html="row.body2_2" style="margin-left: 20px"></div>
                  </div>

                  <div v-if="row.image2_3 || row.mask2_3 || row.body2_3"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-view" v-if="row.image2_3"
                      :style="{backgroundImage: `url(${row.image2_3})`}">
                    </div>
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color2_3} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line2_3} !important`}"></div>
                      <div class="title overline" v-html="row.mask2_3"></div>
                    </div>
                  </div>
                  <div class="col-12" v-if="row.body2_3">
                    <div v-if="row.body2_3" v-html="row.body2_3" style="margin-left: 20px"></div>
                  </div>

                  <div v-if="row.image2_4 || row.mask2_4 || row.body2_4"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color2_4} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line2_4} !important`}"></div>
                      <div class="title overline" v-html="row.mask2_4"></div>
                    </div>
                    <div class="benefits-widget-view" v-if="row.image2_4"
                      :style="{backgroundImage: `url(${row.image2_4})`}">
                    </div>
                  </div>
                  <div class="col-12" v-if="row.body2_4">
                    <div v-if="row.body2_4" v-html="row.body2_4" style="margin-left: 20px"></div>
                  </div>
                  <!-- End Group 2 -->

                  <!-- Group 3 -->
                  <div class="tc-container mb-901" 
                    v-if="row.image3_1 || row.body3_1 || row.image3_2 || row.body3_2 ||
                          row.image3_3 || row.body3_3 || row.image3_4 || row.body3_4 ||
                          row.image3_5 || row.body3_5 || row.image3_6 || row.body3_6 ||
                          row.image3_7 || row.body3_7 || row.image3_8 || row.body3_8 ||
                          row.image3_9 || row.body3_9">
                    <div class="row">
                      <div v-if="row.image3_1" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_1"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_1"/>
                          <div class="description text" v-html="row.body3_1"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_2" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_2"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_2"/>
                          <div class="description text" v-html="row.body3_2"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_3" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_3"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_3"/>
                          <div class="description text" v-html="row.body3_3"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_4" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_4"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_4"/>
                          <div class="description text" v-html="row.body3_4"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_5" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_5"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_5"/>
                          <div class="description text" v-html="row.body3_5"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_6" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_6"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_6"/>
                          <div class="description text" v-html="row.body3_6"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_7" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_7"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_7"/>
                          <div class="description text" v-html="row.body3_7"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_8" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_8"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_8"/>
                          <div class="description text" v-html="row.body3_8"></div>
                        </div>
                      </div>
                      <div v-if="row.image3_9" class="col-lg-4 col-sm-6">
                        <div class="tc-box">
                          <img :src="row.image3_9"
                            class="img-fluid tc-img" style="width: 100% !important"
                            :alt="row.body3_9"/>
                          <div class="description text" v-html="row.body3_9"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Group 3 -->

                  <!-- Group 4 -->
                  <div v-if="row.body4_1 || row.image4_1" class="widget-img-text pt-4 mb-70">
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body4_1" style="margin-left: 20px"></div>
                    </div>
                    <div class="widget-img-k">
                      <img v-if="row.image4_1" :src="row.image4_1" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                  </div>

                  <div v-if="row.body4_2 || row.image4_2" class="widget-img-text pt-4 mb-70">
                    <div class="widget-img-k">
                      <img v-if="row.image4_2" :src="row.image4_2" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body4_2" style="margin-left: 20px"></div>
                    </div>
                  </div>

                  <div v-if="row.body4_3 || row.image4_3" class="widget-img-text pt-4 mb-70">
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body4_3" style="margin-left: 20px"></div>
                    </div>
                    <div class="widget-img-k">
                      <img v-if="row.image4_3" :src="row.image4_3" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                  </div>

                  <div v-if="row.body4_4 || row.image4_4" class="widget-img-text pt-4 mb-70">
                    <div class="widget-img-k">
                      <img v-if="row.image4_4" :src="row.image4_4" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body4_4" style="margin-left: 20px"></div>
                    </div>
                  </div>
                  <!-- End Group 4 -->

                  <!-- Group 5 -->
                  <div v-if="row.image5_1 || row.mask5_1 || row.body5_1"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-view" v-if="row.image5_1"
                      :style="{backgroundImage: `url(${row.image5_1})`}">
                    </div>
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color5_1} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line5_1} !important`}"></div>
                      <div class="title overline" v-html="row.mask5_1"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="row.body5_1" v-html="row.body5_1" style="margin-left: 20px"></div>
                  </div>

                  <div v-if="row.image5_2 || row.mask5_2 || row.body5_2"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color5_2} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line5_2} !important`}"></div>
                      <div class="title overline" v-html="row.mask5_2"></div>
                    </div>
                    <div class="benefits-widget-view" v-if="row.image5_2"
                      :style="{backgroundImage: `url(${row.image5_2})`}">
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="row.body5_2" v-html="row.body5_2" style="margin-left: 20px"></div>
                  </div>

                  <div v-if="row.image5_3 || row.mask5_3 || row.body5_3"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-view" v-if="row.image5_3"
                      :style="{backgroundImage: `url(${row.image5_3})`}">
                    </div>
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color5_3} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line5_3} !important`}"></div>
                      <div class="title overline" v-html="row.mask5_3"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="row.body5_3" v-html="row.body5_3" style="margin-left: 20px"></div>
                  </div>

                  <div v-if="row.image5_4 || row.mask5_4 || row.body5_4"
                    class="benefits-widget pt-4">
                    <div class="benefits-widget-details" 
                      :style="{backgroundColor:`${row.color5_4} !important`}">
                      <div class="bwd-overline" :style="{backgroundColor:`${row.line5_4} !important`}"></div>
                      <div class="title overline" v-html="row.mask5_4"></div>
                    </div>
                    <div class="benefits-widget-view" v-if="row.image5_4"
                      :style="{backgroundImage: `url(${row.image5_4})`}">
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-if="row.body5_4" v-html="row.body5_4" style="margin-left: 20px"></div>
                  </div>
                  <!-- End Group 5 -->


                  <p v-if="row.body6_1 || row.image6_1"><br/></p>
                  <!-- Group 6 -->
                  <div v-if="row.body6_1 || row.image6_1" class="widget-img-text pt-4 mb-70 wit-border-bottom">
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body6_1" style="margin-left: 20px"></div>
                    </div>
                    <div class="widget-img-k">
                      <img v-if="row.image6_1" :src="row.image6_1" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                  </div>

                  <div v-if="row.body6_2 || row.image6_2" class="widget-img-text pt-4 mb-70 wit-border-bottom">
                    <div class="widget-img-k">
                      <img v-if="row.image6_2" :src="row.image6_2" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body6_2" style="margin-left: 20px"></div>
                    </div>
                  </div>

                  <div v-if="row.body6_3 || row.image6_3" class="widget-img-text pt-4 mb-70 wit-border-bottom">
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body6_3" style="margin-left: 20px"></div>
                    </div>
                    <div class="widget-img-k">
                      <img v-if="row.image6_3" :src="row.image6_3" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                  </div>

                  <div v-if="row.body6_4 || row.image6_4" class="widget-img-text pt-4 mb-70 wit-border-bottom">
                    <div class="widget-img-k">
                      <img v-if="row.image6_4" :src="row.image6_4" style="width: 100% !important" class="img-fluid tc-img" />
                    </div>
                    <div class="widget-img-details">
                      <div class="text" v-html="row.body6_4" style="margin-left: 20px"></div>
                    </div>
                  </div>
                  <!-- End Group 6 -->

                 

                </div>
              </div>
            </div>
            <!-- End Content -->




            <!-- Right SideBar -->
            <div v-if="pgLoading" class="accrediation-became">
              <div class="webkit-animation webkit-100-95"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
            </div>
            <div v-if="!pgLoading" class="accrediation-became">

            <!-- Download -->
            <p v-if="basic.has_download">
              <div class="program-download" v-if="basic.has_download">
                <div class="download-catalog">
                    <img v-if="basic.download_image" 
                        :src="basic.download_image" 
                        :alt="basic.download_name" 
                        class="img-fluid" />
                    <div class="title bg-title" 
                        style="text-transform: unset !important" 
                        v-html="basic.download_name">
                    </div>
                    <a v-if="basic.download_file" 
                        :href="basic.download_file" 
                        target="_blank" 
                        download class="download-btn">Download
                    </a>
                </div>
              </div>
            </p>


            <!-- Member -->
            <p v-if="basic.has_application" class="webkit-mt-2">
              <div  class="online-app-ml" v-if="basic.has_application">
                  <div class="title bg-title mb-3" 
                      style="text-transform: unset !important" 
                      v-html="basic.application_name">
                  </div>
                  <router-link 
                        v-if="basic.application_path == 'accreditation-applications' || 
                              basic.application_path == 'certificate-applications'"
                        :to="'/online-applications/v2/'+basic.application_path+'?reefer=popularSearch'"
                        class="read-more" style='margin-bottom: 20px'>
                        <div>Apply Now <span class="icon-back"></span></div>
                      </router-link>
                      <router-link v-else
                        :to="'/online-applications/'+basic.application_path+'?reefer=popularSearch'"
                        class="read-more" style='margin-bottom: 20px'>
                        <div>Apply Now <span class="icon-back"></span></div>
                      </router-link>
              </div>
            </p>

            <!-- FAQ -->
            <p v-if="basic.has_faq" class="webkit-mt-2">
              <div class="accred-faq" v-if="basic.has_faq">
                <router-link v-if="basic.faq_link" :to="basic.faq_link" class="faq-link d-flex mb-30">
                  <div class="faq-bold d-flex align-items-center">FAQ</div>
                  <div class="faq-small d-flex align-items-center">
                      Frequently Asked<br />Questions
                  </div>
                </router-link>
              </div>
            </p>

            <p v-if="basic.has_payment" class="webkit-mt-2">
                <div class="training" v-if="basic.has_payment">
                  <a v-if="basic.payment_link" :href="basic.payment_link"
                      target="_blank" 
                      class="read-more"
                      style="border:1px solid #1b1464">
                      <div>{{ basic.payment_name }} </div>
                  </a>
                </div>
            </p>

            </div>


            

          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <Footer />
  </div>
</template>

<!-- <style scoped src="../../components/common/css/Accreditation.css"></style> -->
<style scoped src="../../components/common/css/Eit.css"></style>
<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header-details {
    /* width: 330px !important */;
  }
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}

.program-content {
  width: calc(100% - 495px);
}
/* Max Width 768px(767.98px) */
@media (max-width: 767.98px) {
  .program-content {
    width: 100%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .accrediation-became {
    display: none;
  }
  .program-content {
    width: calc(100% - 210px);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-content {
    width: calc(100% - 434px);
  }
}

.title-line:after {
  left: 106%;
}
.req-bullet {
  font-size: 16px;
  color: #585858;
  position: relative;
  padding-left: 20px;
  margin-bottom: 30px;
}
.req-bullet:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  border-radius: 50%;
  border: 1px solid #4f17a8;
  width: 8px;
  height: 8px;
}
.requirements-view {
  display: flex;
  justify-content: space-between;
}
.requirements-view > div {
  width: 48%;
}
.requirements-details {
  padding: 30px 0;
}
.program-title {
  font-weight: 600;
  font-size: 23px;
  color: #2b0962;
}
.beneficiaries-list {
  display: flex;
  justify-content: space-between;
}
.beneficiaries-list > div {
  width: 48%;
}
.corporate-widget {
  display: flex;
  justify-content: space-between;
}
.corporate-widget > div {
  width: 50%;
}
.corporate-widget-details {
  background-color: #0101a3;
  padding: 70px 50px;
}
.corporate-widget-details .type,
.corporate-widget-details .title {
  color: #d9e362;
}
.type {
  font-size: 20px;
  position: relative;
  margin-bottom: 40px;
}
.type:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 50px;
  background-color: #d9e362;
  height: 4px;
}
.corporate-widget-details .title {
  font-weight: bold;
  font-size: 40px;
  font-size: 37px;
  line-height: 1;
}
.proof-bullets {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.proof-bullets > div {
  width: 48%;
}
.membership-attainment-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membership-attainment-steps > div {
  width: 48%;
  color: #6e6d76;
  font-size: 17px;
  margin-bottom: 20px;
}
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header-details {
    /* width: 330px !important */;
  }
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    display: none;
  }
}
.read-more > div {
  font-size: 12px;
}
.title {
  line-height: 1.2;
  font-weight: bold;
  color: #2c3e50;
}
.bg-title {
  font-weight: bold;
  font-size: 20px;
  color: #1b1464;
}
.faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 33px;
  color: #1b1464;
}
</style>

<script>

import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import axios from "axios";

export default {
  name: "popularSearch",
  components: {
    
    Navbar: Navbar,
    Footer: Footer
  },
  data() {
    return {
      slug: '',
      row: '',
      programs: [],
      navigation: [],
      pgLoading: true,
    };
  },
  created() {
      this.fetchRow();
  },
  methods: {

    fetchRow() {
      this.pgLoading = true;
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL+'/popularSearch/'+this.$route.params.slug,
        method: 'GET',
        data: {},
        params: {},
      }
      axios(options)
        .then(res => {
          this.pgLoading = false;
          this.basic = res.data.row;
          this.rows = res.data.rows;
          this.programs = [];
          this.navigation = res.data.navigation;
        })
        .catch(() => {})
        .finally(() => {})
    },

  },
};
</script>
