<template>
  <div class="">
    <Navbar />
    <Slider />
    <Section1 :row="row" />
    <Section2 :row="row" />
    <Section3 :row="row" />

    <Footer :containerFluidHome="true" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Slider from "./Slider.vue";
import Section1 from "./Section1.vue";
import Section2 from "./Section2.vue";
import Section3 from "./Section3.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Navbar,
    Slider,
    Section1,
    Section2,
    Section3,
    Footer,
  },
  data() {
    return {
      pgLoading: true,
      row: "",
    };
  },
  watch: {
    $route() {
      this.fetchHome();
    },
  },
  created() {
    // Check Auth
    if (!localStorage.getItem("access_token")) {
      this.authenticated = false;
    } else {
      this.authenticated = true;
    }

    this.fetchHome();
  },
  methods: {
    fetchHome() {
      axios
        .get(window.baseURL + "/landingPages")
        .then((res) => {
          this.pgLoading = false;
          this.row = res.data.rows;
        })
        .catch(() => {});
    },
  },
};
</script>
