<template>
  <div class="page-container">
    <!-- Navbar -->
    <Navbar />
    <!-- Navbar -->

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <div v-if="pgLoading" class="program-header entrepreneurship-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header entrepreneurship-header main-program-header">
          <div class="program-header-details"
            :style="'background:' + row.bgColor + '!important'">
            <div class="align-center">
              <div class="title" v-html="row.bgTitle"></div>
              <!-- <div class="description-type goldColor">Programs</div> -->
            </div>
          </div>
          <div class="program-header-view" :style="{ backgroundImage: `url(` + imageHeader + `) !important` }"></div>
        </div>


        <div class="program-container">
          <div class="program-row">


            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="webkit-animation webkit-100-50"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
              </div>
            </div>

            <div v-if="!pgLoading" class="program-sidebar program-sidebar-bullet">
              <div class="sidebar-link-bullet">
                <a href="#" class="link mb-3">{{ row.title }}</a>
                <ul class="list-unstyled" style="margin-left: 30px">
                  <li v-for="(pro, index) in navigation" :key="index">
                    <router-link
                      :to="{
                        name: 'program-popular-search',
                        params: { slug: pro.slug },
                      }"
                      class="bullet-link"
                      >{{ pro.title }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>


            <div v-if="pgLoading" class="program-content">
              <div class="webkit-animation webkit-50-50"></div>
              <div class="webkit-animation webkit-100-250 webkit-mt-1"></div>  
            </div>

            <div v-if="!pgLoading" class="program-content">
              <div class="description-about" v-html="row.short_body"></div>
              <div class="program-view-head-text">
                <div class="title" v-html="pageTitle"></div>
                <!-- <div class="description-type">Programs</div> -->
              </div>
              <div class="program-entre-row">
                <div class="program-entre-content">
                  <div class="program-entre-text">
                    <div class="medium-entre-title">{{ row.title1 }}</div>
                    <div class="text mb-4" v-html="row.body1"></div>

                    <div class="apply-border-bottom">
                      <div class="medium-entre-title">{{ row.title2 }}</div>
                      <div class="text mb-5" v-html="row.body2"></div>
                    </div>

                    <div class="medium-entre-title">{{ row.title3 }}</div>
                    <div class="text mb-5" v-html="row.body3"></div>
                  </div>
                  <div class="program-entre-application">
                    <div v-if="row.has_application" class="online-app-ml apply-border-bottom">
                      <div class="medium-entre-title" v-html="row.application_name"></div>
                      <router-link 
                        v-if="row.application_path == 'accreditation-applications' || 
                              row.application_path == 'certificate-applications'"
                        :to="'/online-applications/v2/'+row.application_path+'?pname='+pageTitle+'&reefer=Certificate'"
                        class="read-more" style='margin-bottom: 20px'>
                        <div>Apply Now <span class="icon-back"></span></div>
                      </router-link>
                      <router-link v-else
                        :to="'/online-applications/'+row.application_path+'?pname='+pageTitle+'&reefer=Certificate'"
                        class="read-more" style='margin-bottom: 20px'>
                        <div>Apply Now <span class="icon-back"></span></div>
                      </router-link>
                    </div>
                    <div class="apply-border-bottom">
                      <div class="medium-entre-title">{{ row.title4 }}</div>
                      <div class="text mb-3" v-html="row.body4"></div>
                    </div>
                    <div>
                      <div class="medium-entre-title">{{ row.title5 }}</div>
                      <div class="text mb-3" v-html="row.body5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!pgLoading" class="accrediation-became">
              <p v-if="row.has_download">
                <div class="program-download" v-if="row.has_download">
                  <div class="download-catalog">
                      <img v-if="row.download_image" 
                          :src="row.download_image" 
                          :alt="row.download_name" 
                          class="img-fluid" />
                      <div class="title bg-title" 
                          style="text-transform: unset !important" 
                          v-html="row.download_name">
                      </div>
                      <a v-if="row.download_file" 
                          :href="row.download_file" 
                          target="_blank" 
                          download class="download-btn">Download
                      </a>
                  </div>
                </div>
              </p>

              <p v-if="row.has_faq" class="webkit-mt-2">
                <div class="accred-faq">
                  <router-link v-if="row.faq_link" :to="row.faq_link" class="faq-link d-flex mb-30">
                    <div class="faq-bold d-flex align-items-center">FAQ</div>
                    <div class="faq-small d-flex align-items-center">
                        Frequently Asked<br />Questions
                    </div>
                  </router-link>
                </div>
              </p>

              <p class="webkit-mt-2" v-if="row.has_payment">
                  <div class="training">
                    <a v-if="row.payment_link" :href="row.payment_link"
                        target="_blank" 
                        class="read-more"
                        style="border:1px solid #1b1464">
                        <div>{{ row.payment_name }} </div>
                    </a>
                  </div>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>

<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .program-entre-content > div {
      width: 100%;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .entrepreneurship-header .program-header-details {
      width: 400px !important;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }


      .faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 33px;
  color: #1b1464;
}
</style>

<script>

import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import DownloadCatalog from "../../components/DownloadCatalog.vue";
import axios from "axios";
export default {
  name: "Program",
  components: {
    
    Navbar: Navbar,
    Footer: Footer,
    DownloadCatalog: DownloadCatalog,
  },
  data() {
    return {
      row: [],
      pageTitle: "",
      pageImage: "",
      pageBody: "",
      imageHeader: "",
      programs: [],
      navigation: [],

      pgLoading: true,
      //sector_slug: this.$route.params.slug,
    };
  },
  watch: {
    $route() {
      this.fetchRow();
    },
  },
  created() {
    this.fetchRow();
  },
  methods: {
    fetchRow() {
      this.pgLoading = true;
      const url =
        window.baseURL + "/popularSearch/in/programs/" + this.$route.params.slug;
      axios
        .get(url)
        .then((res) => {
          this.pgLoading = false;
          var data = res.data.row;
          this.row = res.data.row;
          this.programs = res.data.related;
          this.pageTitle = data.title;
          this.pageImage = data.image;
          this.pageBody = data.body;
          this.imageHeader = data.image;
          this.navigation = res.data.navigation;
          console.log(data);
        })
        .catch(() => {});
    },
  },
};
</script>
