<template>
    <div class="">
        
        <!-- Header (Topbar) -->
        <header class="u-header u-header--error-page">
            <!-- Header Logo -->
            <!-- <router-link :to="{ name: 'dashboard' }" class="u-header-logo">
                <img class="u-header-logo__icon" src="/assets/svg/logo-mini.svg" alt="404">
                <img class="u-header-logo__text ml-1" src="/assets/svg/logo-text-dark.svg" alt="404" width="140">
            </router-link> -->
            <!-- End Header Logo -->
        </header>
        <!-- End Header (Topbar) -->

        <!-- Main -->
        <main class="u-error-content-wrap">
            <div class="u-error-content container text-center my-auto">
                <h1 class="u-error__title">404</h1>
                <h2 class="u-error__sub-title">Page not found</h2>
                <h4 class="font-weight-semi-bold mb-0">
                    <router-link :to="{ name: 'home' }">Return back</router-link>
                </h4>

            </div>
        </main>
        <!-- End Main -->

    </div>
</template>

<script>
    export default {
        name: 'NotFound',
        mounted() {
            //
        }
    }
</script>