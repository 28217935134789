<template>
  <div>

    <footer>
      <div class="container-fluid">
        <div class="footer-content">
            <div class="bg-title"></div>
            <div class="footer-body">
              <div class="footer-logo d-flex align-items-center">
                <img :src="logo.logo" class="fl-view img-fluid" alt="" />
                <div class="footer-title" v-html="logo.mini_title"></div>
              </div>
              <div class="footer-links">

                <router-link class="footer-link"
                  v-if="(header[3]) ? header[3].status : false"
                  to="/about">{{ header[3].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[1]) ? header[1].status : false"
                  to="/programs">{{ header[1].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[9]) ? header[9].status : false"
                  to="/our-services">{{ header[9].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[10]) ? header[10].status : false"
                  to="/our-partners">{{ header[10].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[4]) ? header[4].status : false"
                  to="/contact">{{ header[4].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[0]) ? header[0].status : false"
                  to="/accreditations">{{ header[0].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[2]) ? header[2].status : false"
                  to="/memberships">{{ header[2].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[6]) ? header[6].status : false"
                  to="/events">{{ header[6].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[7]) ? header[7].status : false"
                  to="/online-trainings">{{ header[7].bgTitle }}</router-link>

                <router-link class="footer-link"
                  v-if="(header[8]) ? header[8].status : false"
                  to="/our-certificates">{{ header[8].bgTitle }}</router-link>

                <!-- <router-link v-for="(pg, idx) in pages" :key="idx"
                    :to="{ name: 'show-page', params:{slug: pg.slug}}" 
                    class="footer-link"
                    :class="(pg.has_footer) ? '' : 'hidden' ">
                    {{ pg.title }}</router-link> -->
              </div>
              <div class="us-footer-contact">
                <div class="title mb-4">Subscribe to our latest insights</div>
                <div class="contact-input d-flex">
                  <input v-model="email" type="search" placeholder="Your mail here" />
                  <button @click="newsletter" class="subscribe-btn">
                    <div class="d-flex align-items-center">Subscribe <span class="icon-back"></span></div>
                  </button>
                </div>
                <div v-if="success" style="color: #080" class="alert-msg">
                  email sent successfully.
                </div>
                <div v-if="error" style="color: #f00" class="alert-msg">
                  this email not valid.
                </div>
              </div>
            </div>
            <div class="footer-bottom">
              <div class="fb-content d-flex flex-wrap align-items-center">
                <div class="text">2021 All Rights Reserved  © AGMEP <router-link class="ml-2 privacy-link" to="/privacy">Privacy Policy </router-link></div>
                <div class="footer-socialmedia">
                  <div class="footer-sm-con">
                    <div class="socialmedia justify-content-between d-flex align-items-center">
                      <div class="follow-text mr-3">Follow us</div>
                      <div class="footer-socialmedia">

                        <a v-for="(social, index) in rows" 
                          class="facebook-icon social-icon" 
                          :href="social.provider_url"
                          target="_blank"
                          :key="index">
                          <i v-if="social.provider.toLowerCase() == 'facebook'"
                            class="fab fa-facebook-f"></i>
                          <i v-if="social.provider.toLowerCase() == 'youtube'"
                            class="fab fa-youtube"></i>
                          <i v-if="social.provider.toLowerCase() == 'linkedin'"
                            class="fab fa-linkedin-in"></i>
                          <i v-if="social.provider.toLowerCase() == 'twitter'"
                            class="fab fa-twitter"></i>
                        </a>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </footer>


  </div>
</template>

<style scoped>
footer {
  padding-top: 40px;
}
.bg-title {
  color: #274379;
  border-bottom: 2px solid #274379;
  font-size: 50px;
  padding-bottom: 10px;
}
.footer-body {
  padding: 40px 0 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-links {
  display: flex;
  flex-wrap: wrap;
  min-width: 36%;
  width: auto;
}
.footer-link {
  color: #274379;
  /* width: 30%; */
  margin-bottom: 16px;
  margin-right: 18px;
  font-size: 19px;
}
.title {
  color: #274379;
  line-height: 1;
}
.subscribe-btn:hover {
  background: #010c5b;
}
.subscribe-btn:active {
  opacity: 0.6;
}
.subscribe-btn {
  background: #274379;
  columns: #fff;
  padding: 5px 18px
}
.subscribe-btn > div {
  color: #fff;
}
.subscribe-btn .icon-back:before {
  color: #fff;
  font-weight: bold;
}
.contact-input input {
  background: #fff !important;
  padding: 5px 15px;
  min-width: 240px;
  width: calc(100% - 128px);
  border: 1px solid #151534;
}
.contact-input {
  max-width: 450px;
}
.fb-content {
  justify-content: space-between;
  padding: 17px 0;
  border-top: 1px solid #274379;
}
.privacy-link {
  color: #274379;
  text-decoration: underline !important;
}
.privacy-link:hover {
  text-decoration: none !important;
}
.text {
  color: #274379;
}
.follow-text {
  font-size: 17px;
}
.socialmedia,
.socialmedia a,
.socialmedia div {
  color: #274379;
}
.socialmedia a {
  margin-left: 19px;
  font-size: 20px;
}
.socialmedia a:hover {
  color: #0020a5;
}
.footer-sm-con {
  border-left: 1px solid #274379;
  line-height: 1;
  padding-left: 15px;
}
.fl-view {
  height: 100px;
}
.footer-title {
  font-weight: bold;
  font-size: 36px;
  color: #274379;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 

}

@media (max-width: 767.98px) { 
  .footer-body {
    display: block;
  }
  .contact-input input {
    min-width: auto;
  }
  /* .footer-links {
    margin-bottom: 10px;
  } */
  .us-footer-contact {
    margin-bottom: 10px;
  }
  .socialmedia {
    justify-content: flex-start !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .footer-body {
    flex-wrap: wrap;
  }
  .footer-links {
    padding: 20px 0
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .footer-body {
    flex-wrap: wrap;
  }
  .us-footer-contact {
    margin-top: 30px;
  }
  .footer-links {
    margin-top: 40px;
    margin-left: 15px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}
</style>

<script>
import axios from "axios";
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      rows: [],
      header: [],
      pages: [],
      logo: [],
      email: "",
      success: false,
      error: false,
    };
  },
  created() {

      axios
      .get(window.baseURL + "/setting")
      .then((res) => {
        this.rows = res.data.social;
        this.header = res.data.header;
        this.logo = res.data.logo;
      })
      .catch(() => { });


      axios
      .get(window.baseURL + "/pages")
      .then((res) => {
        var data = res.data.rows;
        this.pages = res.data.rows;
      })
      .catch(() => {});
      
  },
  methods: {
    newsletter() {
      const options = {
        url: window.baseURL + "/newsletters",
        method: "POST",
        data: {
          email: this.email,
        },
      };

      axios(options)
        .then((res) => {
          console.log(res);
          this.email = "";
          this.success = true;
          this.error = false;
        })
        .catch((err) => {
          console.log(err);
          this.success = false;
          this.error = true;
        });
    },
  }
};
</script>
