<template>
  <div class="page-container">
    <Navbar />

    <div class="content">
      <div class="container-fluid">
        <div class="online-app-header">
          <div class="online-app-details" :style="{ backgroundColor: `${info.bgColor} !important` }">
            <div class="align-center">
              <div class="title goldColor" style="text-transform: unset !important" v-html="info.bgTitle"></div>
              <div class="description-type goldColor" v-html="info.bgSubTitle"></div>
            </div>
          </div>
          <div
            class="online-app-view"
            :style="{
              backgroundImage: `url(${info.image})`,
            }"
          ></div>
        </div>
      </div>

      <div class="steps" style="margin-top: 50px">
        <div class="container" >
            <form>
                <div v-if="!success" class="online-app-flex d-flex justify-content-between">
                    <div class="online-app-avatar">
                        <div class="cover">
                            <img class="cover-view" 
                                :src="
                                row.logo_preview
                                    ? row.logo_preview
                                    : require('../../assets/img/logo.png')
                                "
                                :style="(row.logo_preview) ? '' : 'padding:20px'" 
                                accept="image/*"
                                alt="Image" />
                        </div>
                        <div class="upload-avatar">
                            <input type="file" name="image" @change="onImageChange" />
                            <span class="icon-upload"></span>
                            <div class="upload-text">Upload your logo</div>
                        </div>
                    </div>
                    <div class="online-app-data">
                        <div class="steps-container">
                            <button type="button" 
                                v-for="step in stepsBtns" 
                                :key="step.active" 
                                v-bind:class="{'active': steps == step.active}" 
                                class="step"
                                @click="switchSteps(step.active)">
                                {{ step.title }}
                            </button>
                        </div>
                        <div v-if="steps == 1" class="step-data-1">
                            <div class="alert-text-checkbox mb-4">
                                <div class="alert-text mb-2">
                                    <p v-html="info.text1"></p>
                                </div>
                                <div class="alert-checkbox">
                                    <input id="agreeNote" type="checkbox" :value="1" v-model="row.confirm1"/>
                                    <label for="agreeNote"
                                        :class="{ 'red-border': validation1.confirm1 }">
                                        I Agree</label>
                                </div>
                            </div>
                            <div class="small-text mb-5 row">
                                <div class="col-6">
                                    <p v-html="info.text2"></p>
                                </div>
                                <div class="col-6">
                                    <p v-html="info.text3"></p>
                                </div>
                            </div>
                            <div class="small-title mb-3">Corporate Information</div>
                            <div class="form-inputs mb-5">
                                <div class="form-group width-100" 
                                    :class="{ 'red-border': validation1.name_of_institution }">
                                    <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.name_of_institution"
                                    placeholder="Name Of Institution/ Training Center"
                                    />
                                </div>

                                <div class="form-group width-100"
                                    :class="{ 'red-border': validation1.address }">
                                    <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.address"
                                    placeholder="Address"
                                    />
                                </div>

                                 <div class="form-group d-flex justify-content-between">
                                    <label class="cc-label">Country</label>
                                    <select v-model="row.country" class="select-country">
                                    <option
                                        v-for="(co, index) in countries"
                                        :value="co.value"
                                        :key="index"
                                    >{{ co.value }}</option>
                                    </select>
                                </div>

                                <div class="form-group"
                                    :class="{ 'red-border': validation1.state }">
                                    <input
                                    type="text"
                                    class="form-control"
                                    placeholder="State/Province/Region:"
                                    v-model="row.state"
                                    />
                                </div>

                                <div class="form-group width-100"
                                    :class="{ 'red-border': validation1.type }">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Type-of Organization (Legislation)"
                                        v-model="row.type"
                                    />
                                </div>

                                <div class="form-group"
                                    :class="{ 'red-border': validation1.establishment_date }">
                                    <!-- <input type="date" 
                                        v-model="row.establishment_date" 
                                        placeholder="Establishment date"/>
                                    <span class="icon-calendar ca-date"></span> -->
                                    <Datepicker placeholder="Establishment date" v-model="row.establishment_date"></Datepicker>
                                    <span class="icon-calendar ca-date"></span>
                                </div>

                                <div class="form-group"
                                    :class="{ 'red-border': validation1.commerical_register_no }">
                                    <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Commerical Register No:"
                                    v-model="row.commerical_register_no"
                                    />
                                </div>

                                <div class="form-group"
                                    :class="{ 'red-border': validation1.telephone_no }">
                                    <vue-tel-input 
                                        defaultCountry="US" 
                                        v-model="row.telephone_no" 
                                        placeholder="Telephone No"
                                        ></vue-tel-input>
                                </div>

                                <div class="form-group"
                                    :class="{ 'red-border': validation1.email_address }">
                                    <input
                                    type="email"
                                    class="form-control"
                                    placeholder="E-mail Address"
                                    v-model="row.email_address"
                                    />
                                </div>

                                <div class="form-group width-100"
                                    :class="{ 'red-border': validation1.website_url }">
                                    <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Website Address (URL)"
                                    v-model="row.website_url"
                                    />
                                </div>
                            </div>
                            
                            <div class="online-form-upload mb-40">
                                <div class="small-title mb-3">Documents</div>
                                <div class="form-inputs">
                                    <div class="form-upload-input width-50">
                                        <div class="form-group mb-1 form-group-upload">
                                            <span class="text">Copy Of Documents Of Incorporation</span>
                                            <small class="text small-note">PDF Only</small>
                                            <span class="icon-upload ic-upload ic-u-sm"></span>
                                            <input type="file" 
                                                @change="onFile1Change" 
                                                class="form-control" 
                                                accept="application/pdf"/>
                                            <div v-if="row.file1_preview" class="file-uploaded">
                                                <div class="text">File Uploaded</div>
                                                <div class="view">
                                                </div>
                                            </div>
                                            <div class="absolute-div" v-else></div>
                                        </div>
                                        <small v-html="info.pdf1"></small>
                                    </div>

                                    <div class="form-upload-input width-50">
                                        <div class="form-group mb-1 form-group-upload">
                                            <span class="text">Upload Company Profile</span>
                                            <small class="text small-note">PDF Only</small>
                                            <span class="icon-upload ic-upload ic-u-sm"></span>
                                            <input type="file" 
                                                @change="onFile2Change" 
                                                class="form-control" 
                                                accept="application/pdf"/>
                                            <div v-if="row.file2_preview" class="file-uploaded">
                                                <div class="text">File Uploaded</div>
                                                <div class="view">
                                                </div>
                                            </div>
                                            <div class="absolute-div" v-else></div>
                                        </div>
                                        <small v-html="info.pdf2"></small>
                                    </div>

                                    <div class="form-upload-input width-50">
                                        <div class="form-group mb-1 form-group-upload">
                                            <span class="text">Informational Materials</span>
                                            <small class="text small-note">PDF Only</small>
                                            <span class="icon-upload ic-upload ic-u-sm"></span>
                                            <input type="file" 
                                                @change="onFile3Change" 
                                                class="form-control" 
                                                accept="application/pdf"/>
                                            <div v-if="row.file3_preview" class="file-uploaded">
                                                <div class="text">File Uploaded</div>
                                                <div class="view">
                                                </div>
                                            </div>
                                            <div class="absolute-div" v-else></div>
                                        </div>
                                        <small v-html="info.pdf3"></small>
                                    </div>

                                    <div class="form-upload-input width-50">
                                        <div class="form-group mb-1 form-group-upload">
                                            <span class="text">Curriculum</span>
                                            <small class="text small-note">PDF Only</small>
                                            <span class="icon-upload ic-upload ic-u-sm"></span>
                                            <input type="file" 
                                                @change="onFile4Change" 
                                                class="form-control" 
                                                accept="application/pdf"/>
                                            <div v-if="row.file4_preview" class="file-uploaded">
                                                <div class="text">File Uploaded</div>
                                                <div class="view">
                                                </div>
                                            </div>
                                            <div class="absolute-div" v-else></div>
                                        </div>
                                        <small v-html="info.pdf4"></small>
                                    </div>

                                    <div class="form-upload-input width-50">
                                        <div class="form-group mb-1 form-group-upload">
                                            <span class="text">Facilities</span>
                                            <small class="text small-note">PDF Only</small>
                                            <span class="icon-upload ic-upload ic-u-sm"></span>
                                            <input type="file" 
                                                @change="onFile5Change" 
                                                class="form-control" 
                                                accept="application/pdf"/>
                                            <div v-if="row.file5_preview" class="file-uploaded">
                                                <div class="text">File Uploaded</div>
                                                <div class="view">
                                                </div>
                                            </div>
                                            <div class="absolute-div" v-else></div>
                                        </div>
                                        <small v-html="info.pdf5"></small>
                                    </div>

                                    <div class="form-upload-input width-50">
                                        <div class="form-group mb-1 form-group-upload">
                                            <span class="text">Student Services</span>
                                            <small class="text small-note">PDF Only</small>
                                            <span class="icon-upload ic-upload ic-u-sm"></span>
                                            <input type="file" 
                                                @change="onFile6Change" 
                                                class="form-control" 
                                                accept="application/pdf"/>
                                            <div v-if="row.file6_preview" class="file-uploaded">
                                                <div class="text">File Uploaded</div>
                                                <div class="view">
                                                </div>
                                            </div>
                                            <div class="absolute-div" v-else></div>
                                        </div>
                                        <small v-html="info.pdf6"></small>
                                    </div>
                                </div>
                            </div>

                            <div class="alert-text-checkbox mb-5">
                                <div class="alert-text mb-2">
                                    <p v-html="info.text4"></p>
                                </div>
                                <div class="alert-checkbox">
                                    <input id="agreeNote2" type="checkbox" :value="1" v-model="row.confirm2"/>
                                    <label for="agreeNote2"
                                        :class="{ 'red-border': validation1.confirm2 }">
                                        I Agree</label>
                                </div>
                            </div>




                            <button
                                class="btn main-btn-backdrop mb-5"
                                type="button"
                                @click="continueToStep2()"
                                style="padding: 10px 20px; width: auto;margin-bottom: 10px"
                                >
                                <span>Continue</span>
                            </button>
                        </div>

















                        <div v-if="steps == 2" class="step-data-2">
                            <div class="small-title mb-4">General Characteries</div>
                            <div class="form-checkbox-group mb-60">


                                <div class="form-checkbox-row">
                                    <div class="form-checkbox-title" v-html="info.general1_title"></div>
                                    <div class="form-checkbox-content"
                                        :class="{ 'red-border': validation2.general1 }">
                                        <label class="form-checkbox-label" for="yes1">
                                            <input 
                                                id="yes1"
                                                type="radio" 
                                                name="steps1" 
                                                class="input-switch"
                                                v-model="row.general1"
                                                :value="1"/>
                                            Yes
                                        </label>
                                        <label class="form-checkbox-label" for="no1">
                                            <input 
                                                id="no1"
                                                type="radio" 
                                                name="steps1" 
                                                class="input-switch"
                                                v-model="row.general1"
                                                :value="0"/>
                                            No
                                        </label>
                                        <div class="form-checkbox-text" v-html="info.general1_body"></div>
                                    </div>
                                </div>

                                <div class="form-checkbox-row">
                                    <div class="form-checkbox-title" v-html="info.general2_title"></div>
                                    <div class="form-checkbox-content"
                                        :class="{ 'red-border': validation2.general2 }">
                                        <label class="form-checkbox-label" for="yes2">
                                            <input 
                                                id="yes2"
                                                type="radio" 
                                                name="steps2" 
                                                class="input-switch"
                                                v-model="row.general2"
                                                :value="1"/>
                                            Yes
                                        </label>
                                        <label class="form-checkbox-label" for="no2">
                                            <input 
                                                id="no2"
                                                type="radio" 
                                                name="steps2" 
                                                class="input-switch"
                                                v-model="row.general2"
                                                :value="0"/>
                                            No
                                        </label>
                                        <div class="form-checkbox-text" v-html="info.general2_body"></div>
                                    </div>
                                </div>

                                <div class="form-checkbox-row">
                                    <div class="form-checkbox-title" v-html="info.general3_title"></div>
                                    <div class="form-checkbox-content"
                                        :class="{ 'red-border': validation2.general3 }">
                                        <label class="form-checkbox-label" for="yes3">
                                            <input 
                                                id="yes3"
                                                type="radio" 
                                                name="steps3" 
                                                class="input-switch"
                                                v-model="row.general3"
                                                :value="1"/>
                                            Yes
                                        </label>
                                        <label class="form-checkbox-label" for="no3">
                                            <input 
                                                id="no3"
                                                type="radio" 
                                                name="steps3" 
                                                class="input-switch"
                                                v-model="row.general3"
                                                :value="0"/>
                                            No
                                        </label>
                                        <div class="form-checkbox-text" v-html="info.general3_body"></div>
                                    </div>
                                </div>

                                <div class="form-checkbox-row">
                                    <div class="form-checkbox-title" v-html="info.general4_title"></div>
                                    <div class="form-checkbox-content"
                                        :class="{ 'red-border': validation2.general4 }">
                                        <label class="form-checkbox-label" for="yes4">
                                            <input 
                                                id="yes4"
                                                type="radio" 
                                                name="steps4" 
                                                class="input-switch"
                                                v-model="row.general4"
                                                :value="1"/>
                                            Yes
                                        </label>
                                        <label class="form-checkbox-label" for="no4">
                                            <input 
                                                id="no4"
                                                type="radio" 
                                                name="steps4" 
                                                class="input-switch"
                                                v-model="row.general4"
                                                :value="0"/>
                                            No
                                        </label>
                                        <div class="form-checkbox-text" v-html="info.general4_body"></div>
                                    </div>
                                </div>

                                <div class="form-checkbox-row">
                                    <div class="form-checkbox-title" v-html="info.general5_title"></div>
                                    <div class="form-checkbox-content"
                                        :class="{ 'red-border': validation2.general5 }">
                                        <label class="form-checkbox-label" for="yes5">
                                            <input 
                                                id="yes5"
                                                type="radio" 
                                                name="steps5" 
                                                class="input-switch"
                                                v-model="row.general5"
                                                :value="1"/>
                                            Yes
                                        </label>
                                        <label class="form-checkbox-label" for="no5">
                                            <input 
                                                id="no5"
                                                type="radio" 
                                                name="steps5" 
                                                class="input-switch"
                                                v-model="row.general5"
                                                :value="0"/>
                                            No
                                        </label>
                                        <div class="form-checkbox-text" v-html="info.general5_body"></div>
                                    </div>
                                </div>


                            </div>

                            <div class="small-text mb-5">
                                <p v-html="info.note1"></p>
                            </div>
                            <div class="small-text mb-5">
                                <p v-html="info.not2"></p>
                            </div>

                            <div class="alert-text-checkbox mb-4">
                                <div class="alert-text mb-2">
                                    <p v-html="info.text5"></p>
                                </div>
                                <div class="alert-checkbox">
                                    <input id="agreeNote2" type="checkbox" :value="1" v-model="row.confirm3"/>
                                    <label for="agreeNote2"
                                        :class="{ 'red-border': validation2.confirm3 }">
                                        I Agree</label>
                                </div>
                            </div>

                            <button
                                class="btn main-btn-backdrop mb-5"
                                type="button"
                                @click="continueToStep3"
                                style="padding: 10px 20px; width: auto;margin-bottom: 10px"
                                >
                                <span>Continue</span>
                            </button>
                        </div>















                        <div v-if="steps == 3" class="step-data-3">
                            <div class="small-title mb-3">
                                <p v-html="info.authority_note"></p>
                            </div>
                            <div class="small-text mb-2">
                                <p v-html="info.authority_title" style="font-weight: bold !important"></p>
                            </div>

                            <div class="form-checkbox-row form-checkbox-single-row">
                                <div class="form-checkbox-content form-checkbox-single"
                                    :class="{ 'red-border': validation3.authority1 }">
                                    <input type="checkbox" 
                                            id="checked1" 
                                            name="checked" 
                                            :checked="row.authority1"
                                            @click="onAuthority1"
                                            class="input-switch" />
                                    <label for="checked1" 
                                            class="form-checkbox-text" v-html="info.authority1"></label>
                                </div>
                            </div>

                            <div class="form-checkbox-row form-checkbox-single-row">
                                <div class="form-checkbox-content form-checkbox-single"
                                    :class="{ 'red-border': validation3.authority2 }">
                                    <input type="checkbox" 
                                            id="checked2" 
                                            name="checked" 
                                            :checked="row.authority2"
                                            @click="onAuthority2"
                                            class="input-switch" />
                                    <label for="checked2" 
                                            class="form-checkbox-text" v-html="info.authority2"></label>
                                </div>
                            </div>

                            <div class="form-checkbox-row form-checkbox-single-row">
                                <div class="form-checkbox-content form-checkbox-single"
                                    :class="{ 'red-border': validation3.authority3 }">
                                    <input type="checkbox" 
                                            id="checked3" 
                                            name="checked" 
                                            :checked="row.authority3"
                                            @click="onAuthority3"
                                            class="input-switch" />
                                    <label for="checked3" 
                                            class="form-checkbox-text" v-html="info.authority3"></label>
                                </div>
                            </div>

                            <div class="form-checkbox-row form-checkbox-single-row">
                                <div class="form-checkbox-content form-checkbox-single"
                                    :class="{ 'red-border': validation3.authority4 }">
                                    <input type="checkbox" 
                                            id="checked4" 
                                            name="checked" 
                                            :checked="row.authority4"
                                            @click="onAuthority4"
                                            class="input-switch" />
                                    <label for="checked4" 
                                            class="form-checkbox-text" v-html="info.authority4"></label>
                                </div>
                            </div>

                            <div class="form-checkbox-row form-checkbox-single-row">
                                <div class="form-checkbox-content form-checkbox-single"
                                    :class="{ 'red-border': validation3.authority5 }">
                                    <input type="checkbox" 
                                            id="checked5" 
                                            name="checked" 
                                            :checked="row.authority5"
                                            @click="onAuthority5"
                                            class="input-switch" />
                                    <label for="checked5" 
                                            class="form-checkbox-text" v-html="info.authority5"></label>
                                </div>
                            </div>

                            <div class="form-checkbox-row form-checkbox-single-row">
                                <div class="form-checkbox-content form-checkbox-single"
                                    :class="{ 'red-border': validation3.authority6 }">
                                    <input type="checkbox" 
                                            id="checked6" 
                                            name="checked" 
                                            :checked="row.authority6"
                                            @click="onAuthority6"
                                            class="input-switch" />
                                    <label for="checked6" 
                                            class="form-checkbox-text" v-html="info.authority6"></label>
                                </div>
                            </div>

                            

                            <div class="small-title  pt-5 mt-2 mb-4"><p v-html="info.head"></p></div>
                            <div class="form-inputs mb-5">
                                <div class="form-upload-input width-50">
                                    <div class="form-group"
                                        :class="{ 'red-border': validation3.name }">
                                        <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Name"
                                        v-model="row.name"
                                        />
                                    </div>
                                </div>

                                 <div class="form-upload-input width-50">
                                    <div class="form-group mb-1 form-group-upload">
                                        <span class="text">Signature</span>
                                        <small class="text small-note">PDF Only</small>
                                        <span class="icon-upload ic-upload ic-u-sm"></span>
                                        <input type="file"
                                            @change="onFile7Change" 
                                            class="form-control" 
                                            accept="application/pdf"/>
                                        <div v-if="row.file7_preview" class="file-uploaded">
                                            <div class="text">File Uploaded</div>
                                            <div class="view">
                                            </div>
                                        </div>
                                        <div class="absolute-div" v-else></div>
                                    </div>
                                </div>
                                
                                <div class="form-upload-input width-50">
                                    <div class="form-group"
                                        :class="{ 'red-border': validation3.date }">
     <!--                                    <input type="date" 
                                            v-model="row.date" 
                                            placeholder="Date"/> -->
                                        <Datepicker placeholder="Date" v-model="row.date"></Datepicker>
                                        <span class="icon-calendar ca-date"></span>
                                    </div>
                                </div>
                               
                            </div>

                            <div class="alert-text-checkbox mb-5">
                                <div class="alert-text mb-2">
                                    <p v-html="info.last_confirm"></p>
                                 </div>
                                <div class="alert-checkbox">
                                    <input id="agreeNote2" type="checkbox" :value="1" v-model="row.confirm4"/>
                                    <label for="agreeNote2"
                                        :class="{ 'red-border': validation3.confirm4 }">
                                        I Agree</label>
                                </div>
                            </div>

                            <button
                                  class="btn main-btn-backdrop mb-5"
                                  type="button"
                                  @click="LastStep3"
                                  style="padding: 10px 20px; width: auto;margin-bottom: 10px;width:150px"
                                  id="applyNow"
                                  :disabled="btnLoading || recaptcha"
                                >
                                  <img src="../../assets/loader.svg" class="sm-loader" alt v-if="btnLoading" />
                                  <span v-else>Apply</span>
                                </button>
                                <div v-if="errors" class="alert alert-danger mt-2 text-left">{{ errors }}</div>
                                <vue-recaptcha @verify="onVerify" :sitekey="sitekey"></vue-recaptcha>
                        </div>

                    </div>
                </div>
                <div v-if="success" class="online-app-data">
                  <div class="alert alert-success mt-2 text-left">{{ success }}</div>
              </div>
            </form>
        </div>
    </div>
   
  </div>
    <Footer />
  </div>
</template>


<style src="../../components/common/css/OnlineAppForm.css"></style>
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>
<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import { VueTelInput } from 'vue-tel-input'
//import DatePicker from "../../components/DatePicker.vue";
import VueRecaptcha from 'vue-recaptcha';
import axios from "axios";
import style from './style.css';
import Datepicker from 'vuejs-datepicker';

export default {
  name: "Applications",
  components: {
    Navbar,
    Footer,
    VueTelInput,
    Datepicker,
    VueRecaptcha,
    // Loop,
  },
  data() {
    return {
        sitekey: '6Lc4S6wUAAAAACQyW8zgRI7swPLCtOF-AXTdKNe9',
        recaptcha: true,

        steps: 1,
        stepsBtns: [
            {title: "Step 1", active: 1},
            {title: "Step 2", active: 2},
            {title: "Step 3", active: 3},
        ],
        row: {

            logo_preview: '',
            logo: '',

            name_of_institution: '',
            address: '',
            country: "United States",
            state: '',
            type: '',
            establishment_date: '',
            commerical_register_no: '',
            telephone_no: '',
            email_address: '',
            website_url: '',
            confirm1: '',
            confirm2: '',

            general1: '',
            general2: '',
            general3: '',
            general4: '',
            general5: '',

            confirm3: '',

            authority1: '',
            authority2: '',
            authority3: '',
            authority4: '',
            authority5: '',
            authority6: '',

            name: '',
            date: '',

            confirm4: 0,
            
            file1: '',
            file1_preview: '',
            file2: '',
            file2_preview: '',
            file3: '',
            file3_preview: '',
            file4: '',
            file4_preview: '',
            file5: '',
            file5_preview: '',
            file6: '',
            file6_preview: '',
            file7: '',
            file7_preview: '',
        },

        hasValidation1: false,
        validation1: {
            name_of_institution : false,
            address : false,
            state : false,
            type : false,
            establishment_date : false,
            commerical_register_no : false,
            telephone_no : false,
            email_address : false,
            website_url : false,
            confirm1 : false,
            confirm2 : false,
        },

        hasValidation2: false,
        validation2: {
            general1: false,
            general2: false,
            general3: false,
            general4: false,
            general5: false,
            confirm3 : false,
        },

        hasValidation3: false,
        validation3: {
            authority1: false,
            authority2: false,
            authority3: false,
            authority4: false,
            authority5: false,
            authority6: false,
            name: false,
            date: false,
            confirm4 : false,
        },

        errors: false,
        success: false,
        btnLoading: false,
        info: '',
        countries: [],
        application: '/accreditation-applications',
        app_id: 1,
        type_id: 1,
        pname: '',
    };
  },
  mounted() {},
  computed: {},
  created() {

    if(this.$route.query.pname) {
      this.pname = this.$route.query.pname;
    }
    
    //Check Auth
    // if (!localStorage.getItem("access_token")) {
    //   this.$router.push({ name: "login" });
    // }
    
    if(this.$route.params.slug) {
      this.application = '/'+this.$route.params.slug;

      if(this.application == '/accreditation-applications') {
        this.app_id = 1;
        this.type_id = 1;
      } else {
        this.app_id = 2;
        this.type_id = 0;
      }
    }
    
    this.fetchCountries();
    this.fetchRow();

  },
  methods: {

     onVerify: function (response) {
      this.recaptcha = false;
    },

    continueToStep2() {
        // reset validation
        this.hasValidation1 = false;
        this.validation1.name_of_institution = false;
        this.validation1.address = false;
        this.validation1.state = false;
        this.validation1.type = false;
        this.validation1.establishment_date = false;
        this.validation1.commerical_register_no = false;
        this.validation1.telephone_no = false;
        this.validation1.email_address = false;
        this.validation1.website_url = false;
        this.validation1.confirm1 = false;
        this.validation1.confirm2 = false;

        if (this.row.name_of_institution == '') {
            this.hasValidation1 = true;
            this.validation1.name_of_institution = true;
        }
        if (this.row.address == ''){
            this.hasValidation1 = true;
            this.validation1.address = true;
        } 
        if (this.row.state == ''){
            this.hasValidation1 = true;
            this.validation1.state = true;
        } 
        if (this.row.type == ''){
            this.hasValidation1 = true;
            this.validation1.type = true;
        } 
        if (this.row.establishment_date == ''){
            this.hasValidation1 = true;
            this.validation1.establishment_date = true;
        } 
        if (this.row.commerical_register_no == ''){
            this.hasValidation1 = true;
            this.validation1.commerical_register_no = true;
        } 
        if (this.row.telephone_no == ''){
            this.hasValidation1 = true;
            this.validation1.telephone_no = true;
        }
        if (this.row.email_address == ''){
            this.hasValidation1 = true;
            this.validation1.email_address = true;
        } 
        if (this.row.website_url == ''){
            this.hasValidation1 = true;
            this.validation1.website_url = true;
        } 
        if (this.row.confirm1 == ''){
            this.hasValidation1 = true;
            this.validation1.confirm1 = true;
        } 
        if (this.row.confirm2 == ''){
            this.hasValidation1 = true;
            this.validation1.confirm2 = true;
        } 
        if(!this.hasValidation1) {
            // clear to go
            this.steps = 2;
        }
    },

    
    continueToStep3() {
        // reset validation
        this.hasValidation2 = false;
        this.validation2.general1 = false;
        this.validation2.general2 = false;
        this.validation2.general3 = false;
        this.validation2.general4 = false;
        this.validation2.general5 = false;
        this.validation2.confirm3 = false;

        if (this.row.general1 === ''){
            this.hasValidation2 = true;
            this.validation2.general1 = true;
        } 
        if (this.row.general2 === ''){
            this.hasValidation2 = true;
            this.validation2.general2 = true;
        } 
        if (this.row.general3 === ''){
            this.hasValidation2 = true;
            this.validation2.general3 = true;
        } 
        if (this.row.general4 === ''){
            this.hasValidation2 = true;
            this.validation2.general4 = true;
        } 
        if (this.row.general5 === ''){
            this.hasValidation2 = true;
            this.validation2.general5 = true;
        } 
        if (this.row.confirm3 == ''){
            this.hasValidation2 = true;
            this.validation2.confirm3 = true;
        } 
        if(!this.hasValidation2) {
            // clear to go
            this.steps = 3;
        }

    },

    LastStep3() {
        // reset validation
        this.hasValidation3 = false;
        // this.validation3.authority1 = false;
        // this.validation3.authority2 = false;
        // this.validation3.authority3 = false;
        // this.validation3.authority4 = false;
        // this.validation3.authority5 = false;
        // this.validation3.authority6 = false;

        this.validation3.name = false;
        this.validation3.date = false;

        this.validation3.confirm4 = false;

        // if (this.row.authority1 === ''){
        //     this.hasValidation3 = true;
        //     this.validation3.authority1 = true;
        // } 
        // if (this.row.authority2 === ''){
        //     this.hasValidation3 = true;
        //     this.validation3.authority2 = true;
        // } 
        // if (this.row.authority3 === ''){
        //     this.hasValidation3 = true;
        //     this.validation3.authority3 = true;
        // } 
        // if (this.row.authority4 === ''){
        //     this.hasValidation3 = true;
        //     this.validation3.authority4 = true;
        // } 
        // if (this.row.authority5 === ''){
        //     this.hasValidation3 = true;
        //     this.validation3.authority5 = true;
        // } 
        // if (this.row.authority6 === ''){
        //     this.hasValidation3 = true;
        //     this.validation3.authority6 = true;
        // } 
        if (this.row.confirm4 == ''){
            this.hasValidation3 = true;
            this.validation3.confirm4 = true;
        } 
        if (this.row.name == ''){
            this.hasValidation3 = true;
            this.validation3.name = true;
        } 
        if (this.row.date == ''){
            this.hasValidation3 = true;
            this.validation3.date = true;
        } 
        if(!this.hasValidation3) {
            // clear to go
           this.apply();
        }

    },
    
    apply() {
      this.btnLoading = true;
      this.success = false;
      this.error = false;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };

      const options = {
        url: window.baseURL + '/new-applications',
        method: "POST",
        data: {
            type_id: this.type_id,
            pname: this.pname,

            name_of_institution: this.row.name_of_institution,
            address: this.row.address,
            country: this.row.country,
            state: this.row.state,
            type: this.row.type,
            establishment_date: this.row.establishment_date,
            commerical_register_no: this.row.commerical_register_no,
            telephone_no: this.row.telephone_no,
            email_address: this.row.email_address,
            website_url: this.row.website_url,

            general1: this.row.general1,
            general2: this.row.general2,
            general3: this.row.general3,
            general4: this.row.general4,
            general5: this.row.general5,

            authority1: this.row.authority1,
            authority2: this.row.authority2,
            authority3: this.row.authority3,
            authority4: this.row.authority4,
            authority5: this.row.authority5,
            authority6: this.row.authority6,

            name: this.row.name,
            date: this.row.date,

            logo: this.row.logo,
            file1: this.row.file1,
            file2: this.row.file2,
            file3: this.row.file3,
            file4: this.row.file4,
            file5: this.row.file5,
            file6: this.row.file6,
            file7: this.row.file7,
          //
        },
      };
      axios(options)
        .then((res) => {
          this.btnLoading = false;
          this.errors = false;
          this.success = "Application Completed Successfully.";
        })
        .catch((err) => {
          this.btnLoading = false;
          this.success = false;
          this.errors = err.response ? err.response.data.message : "" + err;
        })
        .finally(() => {});
    },

    // fetchCountries
    fetchCountries() {
      this.countryLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL + "/countries",
        method: "GET",
        data: {},
        params: {},
      };
      axios(options)
        .then((res) => {
          this.countries = res.data.rows.countries;
        })
        .catch(() => {})
        .finally(() => {});
    },

    fetchRow() {
      const url = window.baseURL + '/new-applications/'+this.app_id;
      axios.get(url)
        .then((res) => {
          this.info = res.data.row;
        })
        .catch(() => {})
        .finally(() => {});
    },

    switchSteps: function(id) {
        if(this.steps > id) {
            this.steps = id;
        }
    },
    continueSteps: function(id) {
        this.steps = id;
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.logo_preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo = e.target.result;
      };
    },

    onFile1Change(e) {
      const file = e.target.files[0];
      this.row.file1_preview = URL.createObjectURL(file);
      this.createFile1Base64(file);
    },
    createFile1Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file1 = e.target.result;
      };
    },

    onFile2Change(e) {
      const file = e.target.files[0];
      this.row.file2_preview = URL.createObjectURL(file);
      this.createFile2Base64(file);
    },
    createFile2Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file2 = e.target.result;
      };
    },

    onFile3Change(e) {
      const file = e.target.files[0];
      this.row.file3_preview = URL.createObjectURL(file);
      this.createFile3Base64(file);
    },
    createFile3Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file3 = e.target.result;
      };
    },

    onFile4Change(e) {
      const file = e.target.files[0];
      this.row.file4_preview = URL.createObjectURL(file);
      this.createFile4Base64(file);
    },
    createFile4Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file4 = e.target.result;
      };
    },

    onFile5Change(e) {
      const file = e.target.files[0];
      this.row.file5_preview = URL.createObjectURL(file);
      this.createFile5Base64(file);
    },
    createFile5Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file5 = e.target.result;
      };
    },

    onFile6Change(e) {
      const file = e.target.files[0];
      this.row.file6_preview = URL.createObjectURL(file);
      this.createFile6Base64(file);
    },
    createFile6Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file6 = e.target.result;
      };
    },

    onFile7Change(e) {
      const file = e.target.files[0];
      this.row.file7_preview = URL.createObjectURL(file);
      this.createFile7Base64(file);
    },
    createFile7Base64(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file7 = e.target.result;
      };
    },



    onAuthority1(){
        if(this.row.authority1)
            this.row.authority1 = 0;
        else
            this.row.authority1 = 1;
    },
    onAuthority2(){
        if(this.row.authority2)
            this.row.authority2 = 0;
        else
            this.row.authority2 = 1;
    },
    onAuthority3(){
        if(this.row.authority3)
            this.row.authority3 = 0;
        else
            this.row.authority3 = 1;
    },
    onAuthority4(){
        if(this.row.authority4)
            this.row.authority4 = 0;
        else
            this.row.authority4 = 1;
    },
    onAuthority5(){
        if(this.row.authority5)
            this.row.authority5 = 0;
        else
            this.row.authority5 = 1;
    },
    onAuthority6(){
        if(this.row.authority6)
            this.row.authority6 = 0;
        else
            this.row.authority6 = 1;
    },



    
  },
  
};
</script>


<style>
.hidden { display: none !important }
.form-checkbox-text, .form-checkbox-title { font-size: 15px !important }

.form-checkbox-content { align-items: unset !important  }
.input-switch { margin-top: 3px !important }
</style>