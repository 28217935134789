<template>
  <div class="page-container">
    <Navbar />

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <!-- Background -->
        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor:`${row.bgColor} !important`}">
            <div class="align-center">
              <div class="title" v-html="row.bgTitle"></div>
            </div>
          </div>
          <div class="program-header-view" :style="{backgroundImage:`url(${row.image})`}"></div>
        </div>
        <!-- End Background -->

        <div class="program-container">
          <div class="program-row">

            <!-- Left Sidebar -->
            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="webkit-animation webkit-100-50"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
              </div>
            </div>
            <div v-if="!pgLoading" class="program-sidebar">
                <div class="sidebar-link" v-for="(nav, index) in navigation" :key="index">
                  <router-link :to="{ name: 'show-services', params: {slug: nav.slug}}"
                      class="link"
                      :class="(nav.slug == slug) ? 'active' : ''">
                      {{ nav.title }}
                  </router-link>
                </div>
            </div>
            <!-- End Left Sidebar -->



            <!-- Content -->
            <div class="program-content">

                
                <div v-if="pgLoading">
                  <div class="webkit-animation webkit-50-50"></div>
                  <div class="webkit-animation webkit-100-250 webkit-mt-1"></div>
                </div>

                <div v-if="!pgLoading">
                  <!-- Group 1 -->
                  <div v-if="row.body1_1" class="description-about">
                    <div v-html="row.body1_1"></div>
                  </div>
                  <!-- Group 1 -->

                  <!-- Group 2 -->
                  <div v-if="row.body2_1" class="acc-widget mb-90">
                    <div class="description-about" v-html="row.body2_1"></div>
                  </div>

                  <div v-if="row.body2_2 || row.body2_3 || row.body2_4" class="acc-widget mb-70">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="acc-box" v-if="row.body2_2">
                          <div class="description-about" v-html="row.body2_2"></div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="acc-box" v-if="row.body2_3">
                          <div class="description-about" v-html="row.body2_3"></div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="acc-box" v-if="row.body2_4">
                          <div class="description-about" v-html="row.body2_4"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.body2_5" class="tail-title mb-70">
                    <div class="extra-font" v-html="row.body2_5"></div>
                  </div>

                  <div v-if="row.body2_6" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_6" :src="row.image2_6" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_6"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_7" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_7" :src="row.image2_7" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_7"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_8" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_8" :src="row.image2_8" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_8"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_9" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_9" :src="row.image2_9" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_9"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_10" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_10" :src="row.image2_10" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_10"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_11" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_11" :src="row.image2_11" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_11"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_12" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_12" :src="row.image2_12" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_12"></div>
                    </div>
                  </div>
                  <div v-if="row.body2_13" class="widget-accreditation d-flex justify-content-between">
                    <div class="wa-view">
                      <img v-if="row.image2_13" :src="row.image2_13" class="img-fluid" alt />
                    </div>
                    <div class="wa-text">
                      <div v-html="row.body2_13"></div>
                    </div>
                  </div>
                  <!-- Group 2 -->


                  <!-- Group 3 -->
                  <div v-if="row.body3_1" class="acc-widget mb-60">
                    <div class="description-about" v-html="row.body3_1"></div>
                  </div>

                  <div class="widget-imgs-bullets mb-70" style="display: none"></div>

                  <div v-if="row.image3_2 || row.mask3_2 || row.body3_2_1 || row.body3_2_2" 
                    class="widget-imgs-bullets mb-70">

                    <div v-if="row.image3_2 || row.mask3_2" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_2" :src="row.image3_2" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_2} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_2} !important`}"></div>
                        <div class="title" v-html="row.mask3_2"></div>
                      </div>
                    </div>

                    <div class="row col-12" v-if="row.body3_2_1 || row.body3_2_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_2_1" v-html="row.body3_2_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_2_2" v-html="row.body3_2_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_3 || row.mask3_3 || row.body3_3_1 || row.body3_3_2" 
                    class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_3 || row.mask3_3" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_3" :src="row.image3_3" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_3} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_3} !important`}"></div>
                        <div class="title" v-html="row.mask3_3"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_3_1 || row.body3_3_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_3_1" v-html="row.body3_3_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_3_2" v-html="row.body3_3_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_4 || row.mask3_4 || row.body3_4_1 || row.body3_4_2" 
                      class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_4 || row.mask3_4" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_4" :src="row.image3_4" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_4} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_4} !important`}"></div>
                        <div class="title" v-html="row.mask3_4"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_4_1 || row.body3_4_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_4_1" v-html="row.body3_4_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_4_2" v-html="row.body3_4_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_5 || row.mask3_5 || row.body3_5_1 || row.body3_5_2"
                     class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_5 || row.mask3_5" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_5" :src="row.image3_5" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_5} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_5} !important`}"></div>
                        <div class="title" v-html="row.mask3_5"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_5_1 || row.body3_5_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_5_1" v-html="row.body3_5_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_5_2" v-html="row.body3_5_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_6 || row.mask3_6 || row.body3_6_1 || row.body3_6_2" 
                    class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_6 || row.mask3_6" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_6" :src="row.image3_6" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_6} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_6} !important`}"></div>
                        <div class="title" v-html="row.mask3_6"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_6_1 || row.body3_6_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_6_1" v-html="row.body3_6_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_6_2" v-html="row.body3_6_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_7 || row.mask3_7 || row.body3_7_1 || row.body3_7_2" 
                    class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_7 || row.mask3_7" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_7" :src="row.image3_7" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_7} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_7} !important`}"></div>
                        <div class="title" v-html="row.mask3_7"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_7_1 || row.body3_7_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_7_1" v-html="row.body3_7_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_7_2" v-html="row.body3_7_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_8 || row.mask3_8 || row.body3_8_1 || row.body3_8_2" 
                    class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_8 || row.mask3_8" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_8" :src="row.image3_8" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_8} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_8} !important`}"></div>
                        <div class="title" v-html="row.mask3_8"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_8_1 || row.body3_8_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_8_1" v-html="row.body3_8_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_8_2" v-html="row.body3_8_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="row.image3_9 || row.mask3_9 || row.body3_9_1 || row.body3_9_2" 
                      class="widget-imgs-bullets mb-70">
                    <div v-if="row.image3_9 || row.mask3_9" class="widget-moving-accred mb-90">
                      <div class="wm-view">
                        <img v-if="row.image3_9" :src="row.image3_9" alt />
                      </div>
                      <div class="wm-details" :style="{backgroundColor:`${row.color3_9} !important`}">
                        <div class="wm-details-tline" :style="{backgroundColor:`${row.line3_9} !important`}"></div>
                        <div class="title" v-html="row.mask3_9"></div>
                      </div>
                    </div>
                    <div class="row col-12" v-if="row.body3_9_1 || row.body3_9_2">
                      <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_9_1" v-html="row.body3_9_1"  style="margin-left: 20px"></div>
                      </div>
                       <div class="col-md-6 col-sm-12">
                         <div v-if="row.body3_9_2" v-html="row.body3_9_2"  style="margin-left: 20px"></div>
                      </div>
                    </div>
                  </div>
                  <!-- Group 3 -->


                  <!-- Group 4 -->
                  <div v-if="row.body4_0" class="acc-widget mb-90">
                    <div class="title-jumbo" v-html="row.body4_0"></div>
                  </div>

                  <div class="widgets-steps">

                    <div v-if="row.body4_1" class="widget-step">
                      <div class="steps-counter">1</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_1"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_2" class="widget-step">
                      <div class="steps-counter">2</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_2"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_3" class="widget-step">
                      <div class="steps-counter">3</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_3"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_4" class="widget-step">
                      <div class="steps-counter">4</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_4"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_5" class="widget-step">
                      <div class="steps-counter">5</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_5"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_6" class="widget-step">
                      <div class="steps-counter">6</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_6"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_7" class="widget-step">
                      <div class="steps-counter">7</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_7"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_8" class="widget-step">
                      <div class="steps-counter">8</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_8"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_9" class="widget-step">
                      <div class="steps-counter">9</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_9"></div>
                      </div>
                    </div>
                    <div v-if="row.body4_10" class="widget-step">
                      <div class="steps-counter">10</div>
                      <div class="steps-details">
                        <div class="text" v-html="row.body4_10"></div>
                      </div>
                    </div>

                  </div>
                  <!-- Group 4 -->

                </div>
                

            </div>
            <!-- End Content -->




            <!-- Right SideBar -->
            <div v-if="pgLoading" class="accrediation-became">
              <div class="webkit-animation webkit-100-95"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
              <div class="webkit-animation webkit-100-95 webkit-mt-1"></div>
            </div>
            <div v-if="!pgLoading" class="accrediation-became">
              <!-- Download -->
              <p>
                <div class="program-download" v-if="row.has_download">
                  <div class="download-catalog">
                      <img v-if="row.download_image" 
                          :src="row.download_image" 
                          :alt="row.download_name"
                          class="img-fluid" />
                      <div class="title" style="text-transform: unset !important">
                          {{ row.download_name }}
                      </div>
                      <a v-if="row.download_file"
                          :href="row.download_file"
                          target="_blank"
                          download class="download-btn">Download
                      </a>
                  </div>
                </div>
              </p>
              <!-- End Download -->

              <!-- Application -->
              <p class="webkit-mt-2">
                <div class="training online-app-ml" v-if="row.has_application">
                  <div class="bg-title mb-3" style="text-transform: unset !important" 
                  v-html="row.application_name"></div>

                  <router-link 
                    v-if="row.application_path == 'accreditation-applications' || 
                          row.application_path == 'certificate-applications'"
                    :to="'/online-applications/v2/'+row.application_path+'?reefer=Accreditation'"
                    class="read-more">
                    <div>Apply Now <span class="icon-back"></span></div>
                  </router-link>
                  <router-link v-else
                    :to="'/online-applications/'+row.application_path+'?reefer=Accreditation'"
                    class="read-more">
                    <div>Apply Now <span class="icon-back"></span></div>
                  </router-link>
                  
                </div>
              </p>
              <!-- End Application -->

              <!-- FAQ -->
              <p class="webkit-mt-2">
                <div class="accred-faq" v-if="row.has_faq">
                    <router-link v-if="row.faq_link" :to="row.faq_link" class="faq-link d-flex mb-30">
                        <div class="faq-bold d-flex align-items-center">FAQ</div>
                        <div class="faq-small d-flex align-items-center">
                            Frequently Asked<br />Questions
                        </div>
                    </router-link>
                </div>
              </p>
              <!-- End FAQ -->

              <p class="webkit-mt-2">
                <div class="training" v-if="row.has_payment">
                  <a v-if="row.payment_link" :href="row.payment_link"
                      target="_blank" 
                      class="read-more"
                      style="border:1px solid #1b1464">
                      <div>{{ row.payment_name }} </div>
                  </a>
                </div>
              </p>

            </div>
            <!-- End Right Sidebar -->



          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <Footer />
  </div>
</template>

<style scoped src="../../components/common/css/Accreditation.css">
</style>

<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}


.widget-accreditation:nth-of-type(even) {
  flex-direction: row-reverse;
}
.wa-bullet {
  padding-left: 30px;
  position: relative;
  color: #777;
  margin-bottom: 20px;
}
.wa-bullet:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 10px;
  border: 1px solid #1b1464;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.widget-imgs-bullets:nth-of-type(even) .widget-moving-accred {
  flex-direction: row-reverse;
}
@media (min-width: 1200px) {
.widget-imgs-bullets:nth-of-type(even) .widget-moving-accred img {
  transform: translateX(-40px);
}
}

.title-jumbo {
  color: #3b3b3b !important;
  line-height: 1;
  padding-left: 25px;
  border-left: 5px solid #d0d877;
}
.hidden { display: none !important }


.bg-title {
  font-weight: bold;
  font-size: 20px;
  color: #1b1464;
}
.faq-small {
  font-size: 15px;
  line-height: 1.2;
  padding-left: 7px;
  color: #666;
}
.faq-bold {
  font-weight: bolder;
  font-size: 33px;
  color: #1b1464;
}
</style>

<script>

import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import axios from "axios";

export default {
  name: "our-services",
  components: {
    Navbar: Navbar,
    Footer: Footer
  },
  data() {
    return {
      slug: '',
      row: '',
      navigation: [],
      pgLoading: true,
    };
  },
  watch: {
    $route() {
      if(this.$route.params.slug) {
        this.slug = this.$route.params.slug;
      }
      this.fetchRow();
    },
  },
  created() {

    if(this.$route.params.slug) {
      this.slug = this.$route.params.slug;
    }
    this.fetchRow();
  },
  methods: {

    fetchRow() {
      this.pgLoading = true;
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
      };
      let baseURL = window.baseURL+'/our-services';
      if(this.slug) {
        baseURL = window.baseURL+'/our-services/'+this.slug;
      }
      const options = {
        url: baseURL,
        method: 'GET',
        data: {},
        params: {},
      }
      axios(options)
        .then(res => {
          this.pgLoading = false;
          this.row = res.data.row;
          this.navigation = res.data.navigation;
        })
        .catch(() => {})
        .finally(() => {})
    },

  },
};
</script>
