<template>
  <div class="page-container">
    <!-- Navbar -->
    <Navbar />
    <!-- Navbar -->

    <!-- Content -->
    <div class="content">
      <div class="container-fluid">

        <div v-if="pgLoading" class="program-header main-program-header">
            <div class="webkit-animation webkit-100-440"></div>
        </div>
        <div v-if="!pgLoading" class="program-header main-program-header">
          <div class="program-header-details" :style="{backgroundColor: rows.bgColor + ' !important'}">
            <div class="align-center">
              <div class="title" v-html="rows.bgTitle"></div>
              <!-- <div class="description-type goldColor">Programs</div> -->
            </div>
          </div>
          <div
            class="program-header-view"
            :style="{backgroundImage:`url(`+ imageHeader +`) !important`}"
          ></div>
        </div>


        <div class="program-container">
          <div class="program-row">

            <div v-if="pgLoading" class="program-sidebar">
              <div class="sidebar-link">
                <div class="webkit-animation webkit-100-50"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
                <div class="webkit-animation webkit-100-50 webkit-mt-1"></div>
              </div>
            </div>
            <div v-if="!pgLoading" class="program-sidebar">
              <div class="sidebar-link" v-for="(link, index) in sidebarLinks" :key="index">
                <router-link :to="{ name: 'sector-programs', params: {slug: link.slug}}"
                  class="link" v-html="link.title"></router-link>
              </div>
            </div>


            <div v-if="pgLoading" class="program-content">
              <div class="webkit-animation webkit-50-50"></div>
              <div class="webkit-animation webkit-100-250 webkit-mt-1"></div>  
            </div>
            <div v-if="!pgLoading" class="program-content">

              <div class="description-about" v-html="pageBody"></div>
              <div class="program-view-head-text">
                <!-- <div class="title" v-html="pageTitle"></div> -->
                <!-- <div class="description-type">Programs</div> -->
              </div>
              <div class="program-box-row">


                <div class="program-box-col" v-for="(program, index) in programs" :key="index">
                  <div class="title" v-html="program.title"></div>
                  <div class="description" v-html="program.subtitle"></div>
                  <div class="text" v-html="program.short_body"></div>
                  <router-link
                    :to="{name: 'program-detail-programs', params: {slug: program.slug}}"
                    class="read-more" >
                    <div>
                      Read More
                      <span class="icon-back"></span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <!-- <DownloadCatalog /> -->
            <div class="program-download"></div>

          </div>
        </div>
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>

<style>
.sidebar-link .link p {
  margin: 0;
}
.sidebar-link .link span {
  display: block;
  font-size: 16px !important;
  font-size: 17px;
  font-weight: bold;
  color: #6e6d76 !important;
  line-height: 1;
}
</style>

<style scoped>
@media (max-width: 767.98px) {
  /* .became-a,
  .requirements-widget,
  .beneficiaries-widget,
  .corporate-widget,
  .membership-proof,
  .membership-attainment {
    display: none !important;
  } */
  .corporate-widget > div {
    width: 100%;
  }
  .corporate-widget-view {
    height: 200px;
  }
  .corporate-widget {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .beneficiaries-list {
    flex-wrap: wrap;
  }
  .requirements-view {
    flex-wrap: wrap;
  }
  .requirements-view > div {
    width: 100%;
  }
  .requirements-img {
    height: 250px;
  }
  .beneficiaries-list > div {
    width: 100%;
  }
  .membership-attainment-steps > div,
  .proof-bullets > div,
  .corporate-widget-details {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .corporate-widget {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .corporate-widget > div {
    width: 100%;
    /* height: 300px; */
  }
  .corporate-widget-view {
    /* display: none; */
    /* height: 300px; */
    height: 300px;
  }
  .widget-moving-accred > div {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .program-header.main-program-header .title:after {
    height: 10px;
  }
  .corporate-widget > div {
    width: 100%;
  }
}

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .program-box-col {
      width: 100%;
    }
  }

  /* Max Width 768px(767.98px) */
  @media (max-width: 767.98px) {
    .program-header .program-header-details .description-type {
      font-size: 27px;
      margin-top: 10px;
    }
    .program-view-head-text .title {
      margin-bottom: 10px;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
</style>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import DownloadCatalog from "../../components/DownloadCatalog.vue";
import axios from "axios";

export default {
  name: "Program",
  components: {
    Navbar: Navbar,
    Footer: Footer,
    DownloadCatalog: DownloadCatalog,
  },
  data() {
    return {
      programs: [],
      pageTitle: "",
      sidebarLinks: [],
      articles: [],
      imageHeader: "",
      pageBody: "",

      sector_slug: this.$route.params.slug,
      pgLoading: true,
    };
  },
  watch: {
    $route() {
      this.fetchRow();
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.fetchRow();
  },
  methods: {
    fetchRow() {
      axios
        .get(window.baseURL + "/programs")
        .then((res) => {
          var data = res.data.rows;
          console.log(data);
          for (var i = 0; i < data.length; i++) {
            if (data[i].sectors != "") {
              this.sidebarLinks = data[i].sectors;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // https://api.mazadak.net/api/v1/programs/sectors/Business-Adminsitrators/products
      const url =
        window.baseURL +
        "/programs/sectors/" +
        this.$route.params.slug +
        "/products";
      axios
        .get(url)
        .then((res) => {
          this.pgLoading = false;
          var data = res.data.row;
          this.rows = data;
          this.pageTitle = data.title;
          this.pageBody = data.body;
          this.programs = data.programs;
          this.imageHeader = data.image;
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Check Auth
    // if (!localStorage.getItem('access_token')) {
    //   this.$router.push({ name: 'Login' });
    // }
  },
};
</script>
