<template>
  <div class="slider-container">
    <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(row, index) in rows" :key="index" :data-swiper-autoplay="row.duration + '000'">
          <div class="slide-header">
            <div class="slide-header-view" :style="{backgroundImage: `url(${row.file})`}"></div>
            <div class="slide-header-details" :style="{backgroundColor: `${row.bgColor} !important`}">
              <div class="shd-center">
                <div class="top-line" :style="{backgroundColor: `${row.lineColor} !important`}"></div>
                <div class="bg-title mb-3" v-html="row.title"></div>
                <div class="text mb-4 white-color" v-html="row.body"></div>
                <router-link :to="row.redirect" class="nml-btn">
                  <div class="d-flex align-items-center">
                    <span class="nml-title" v-html="row.button"></span>
                    <span class="icon-arrow-right icon"></span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<style scoped>
.swiper-container {
  height: calc(100vh);
  min-height: 600px;
  position: relative;
}
.slide-header {
  height: 100%;
  background-size: cover;
  display: flex;
}
.slide-header-view {
  background-size: cover;
  background-position: center;
}
.slide-header-details {
  padding: 50px 60px;
  display: flex;
  place-items: center;
}
.bg-title {
  color: #fff;
  font-size: 45px;
}
.top-line {
  height: 5px;
  width: 80px;
  margin-bottom: 20px;
}
.slide-header > div {
  width: 50%;
}
.text {
  font-size: 26px;
  font-weight: 400;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    
}

@media (max-width: 767.98px) {
  .swiper-container {
    height: auto;
    min-height: auto;
  }
  .slide-header > div {
    width: 100%;
  }
  .slide-header-view {
    height: 450px;
  }
  .slide-header {
    /* padding: 100px 20px 95px; */
    flex-wrap: wrap;
  }
  .widget {
    position: static;
  }
  .bg-title {
    font-size: 28px;
    margin-bottom: 24px;
  }
  .text {
    font-size: 18px;
  }
  .slide-header-details {
    padding: 50px 30px 80px
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .bg-title {
    font-size: 56px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .swiper-container {
    height: calc(100vh);
    /* min-height: auto; */
  }
  .slide-header {
    padding: 0;
  }
  .bg-title {
    font-size: 34px;
  }
  .text {
    font-size: 17px;
  }
  .slide-header-details {
    padding: 50px 40px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
</style>

<script>
// Very Important npm install swiper@5.4.5 
import axios from 'axios';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: "Slider",
//   props: ['row'],
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  
  data() {
    return {
      pgLoading: true,
      duration: 4,
      rows: [],
      swiperOptions: {
        loop: false,
        speed: 800,
        autoplay: {
            
          // delay: 4000, or data-swiper-autoplay="2000" in html
        },
        spaceBetween: 10,
        // slidesPerView: 3,
        navigation: {
            nextEl: '.swiperNext',
            prevEl: '.swiperPrev'
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets"
        },
      }
    };
  },
  created() {
    this.fetchSlider();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    prev() {
        this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
        this.$refs.mySwiper.$swiper.slideNext();
    },
    fetchSlider() {
      axios
        .get(window.baseURL + "/sliders")
        .then((res) => {
          this.pgLoading = false;
          this.rows = res.data.rows;
        })
        .catch(() => {});
    },
  },
};
</script>
