<template>
  <div v-if="cookie" class="cookies-notice">
    <div class="cookies-container container">
      <p>
        We use cookies to enhance the user experience and improve the quality of our site. Accept cookies to hide this message.
        <br />Read our privacy policy
        <router-link to="/privacy" class="here-link">here</router-link>.
      </p>
      <div class="accept-cookie">
        <button @click="setCookie()" class="read-more" name="button">
          <div>Accept</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Cookies",
  data() {
    return {
      cookie: true,
    };
  },
  methods: {
    setCookie() {
      this.cookie = false;
      localStorage.setItem('cookie_swac', false);
    },
  },
  created() {
    if(localStorage.getItem('cookie_swac')) {
      this.cookie = false;
    } else {
      this.cookie = true;
    }
  },
};
</script>

<style scoped>
.cookies-notice {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 50px;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: #000F80;
}
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
p {
  font-size: 17px;
  line-height: 1.5;
  color: #b2b2b2;
  margin: 0;
  font-weight: normal;
}
.read-more {
  padding: 6px 30px;
  border: 1px solid #fff;
}
.read-more:hover {
  background: #fff;
}
.read-more:hover > div {
  color: #1b1464;
}
.here-link {
  color: #b2b2b2;
  text-decoration: underline !important;
}
.read-more > div {
  font-size: 20px;
  color: #fff;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .test {
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
  .cookies-notice {
    padding: 30px;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
    display: block;
  }
  .accept-cookie {
    margin-top: 15px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .accept-cookie {
    margin-left: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .accept-cookie {
    margin-left: 20px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .accept-cookie {
    margin-left: 20px;
  }
}
</style>
