<template>
  <div class="page-container">
    <AuthHeader />
    <div class="navbar-auth-sm">
      <Navbar />
    </div>
    <div class="auth center-vertically">
      <div class="auth-content">
        <div class="title">Create an Account</div>
        <div class="auth-box">
          <div v-if="errors.length" class="alert alert-danger text-left">{{ errors }}</div>
          <div class="input-group mb-4">
            <input
              v-model="user.first_name"
              type="text"
              class="form-control"
              name="first_name"
              placeholder="First Name"
              required
            />
          </div>
          <div class="input-group mb-4">
            <input
              v-model="user.last_name"
              type="text"
              class="form-control"
              name="last_name"
              placeholder="Last Name "
              required
            />
          </div>
          <div class="input-group mb-4">
            <input
              v-model="user.email"
              type="email"
              class="form-control"
              name="email"
              placeholder="Primary Email"
              required
            />
          </div>
          <div class="input-group mb-4">
            <input
              v-model="user.password"
              type="password"
              class="form-control"
              name="password"
              placeholder="Password"
              required
              autocomplete="off"
            />
          </div>
          <div class="input-group d-flex justify-content-between mb-4">
            <label class="cc-label">Country</label>
            <select v-model="user.country" class="select-country" name="country">
              <option
                v-for="(country, index) in countries"
                :value="country.value"
                :key="index"
              >{{ country.value }}</option>
            </select>
          </div>
          <button class="btn main-btn-backdrop" type="button" @click="register" :disabled="recaptcha" style="margin-bottom: 10px">
            <img src="../../assets/loader.svg" class="sm-loader" alt v-if="btnLoading" />
            <span v-else>Create an Account</span>
          </button>
          <vue-recaptcha @verify="onVerify" :sitekey="sitekey"></vue-recaptcha>
        </div>
        <div class="dont-have-text">
          Already have a AIPS email and password?
          <router-link to="/login">Log in</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="../../components/common/css/Auth.css"></style>
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>
<script>
import Navbar from "@/components/Navbar.vue";
import axios from "axios";
import VueRecaptcha from 'vue-recaptcha';
import AuthHeader from "./AuthHeader.vue";

export default {
  name: "Register",
  components: {
    Navbar,
    VueRecaptcha,
    AuthHeader
  },
  data() {
    return {
      sitekey: '6Lc4S6wUAAAAACQyW8zgRI7swPLCtOF-AXTdKNe9',
      recaptcha: true,

      user: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        country: "United States",
      },
      btnLoading: false,
      countryLoading: true,
      //countries: [{ value: "United States" }, { value: "Egypt" }],
      countries: [],
      errors: "",
    };
  },
  mounted() {},
  created() {

    if (localStorage.getItem('access_token')) {
      this.$router.push({ name: 'home' });
    }

    this.fetchCountries();
  },
  methods: {
    // fetchCountries
    onVerify: function (response) {
      this.recaptcha = false;
    },

    fetchCountries() {
      this.countryLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL + "/countries",
        method: "GET",
        data: {},
        params: {},
      };
      axios(options)
        .then((res) => {
          this.countryLoading = false;
          this.countries = res.data.rows.countries;
        })
        .catch(() => {})
        .finally(() => {});
    },

    register() {
      this.btnLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: window.baseURL + "/auth/register",
        method: "POST",
        data: {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          password: this.user.password,
          country: this.user.country,
        },
      };
      axios(options)
        .then(() => {
          this.btnLoading = false;
          this.$router.push({ name: "verify" });
        })
        .catch((err) => {
          this.btnLoading = false;
          let errors = err.response ? err.response.data.message : "" + err;
          this.errors = errors;
        })
        .finally(() => {});
    },
  },
};
</script>
