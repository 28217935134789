<template>
  <div>
  <nav v-bind:class="{'active': toggleNavbarBg || toggleSearch}">
    <div class="container-fluid">
      <div class="navbar">
        <router-link to="/" class="nav-logo d-flex align-items-center">
          <img :src="logo.logo" class="img-fluid" alt="" />
          <div class="logo-title white-color" v-html="logo.title"></div>
        </router-link>

        <div class="nav-tools">
          <button v-if="authenticated"
              type="button"
              name="button"
              class="navbar-action"
              data-toggle="dropdown">
            <div class="showDropdown">
                <img :src="(avatar) ? avatar : require('../assets/img/avatar.jpg')" 
                    alt="" 
                    class="img-fluid" 
                    style="width:38px;height:38px;border-radius: 50%;">
                <div class="dropdown-menu">
                  <router-link to="/me/profile" class="dropdown-item">Profile</router-link>
                  <a href="javascript:;" class="dropdown-item" @click="logout()">Logout</a>
                </div>
            </div>
          </button>
          
          <router-link to="/login" v-if="!authenticated" class="nav-su">
            <span class="icon-user icon"></span>
          </router-link>
          <span class="ml-2 mr-2 vertical-line">|</span>
          <button  @click="toggleSearch = !toggleSearch" class="nav-su">
            <span v-if="!toggleSearch" class="icon-search icon"></span>
            <span v-else class="icon-cancel icon"></span>
          </button>
          <div @click="toggleMenu = !toggleMenu" id="menuBtn" class="menu-btn ml-3">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
            <div class="menu-text">Menu</div>
          </div>
        </div>
        <!-- <div class="nav-links" v-if="toggleMenu">
          <router-link v-if="(header[0]) ? header[0].status : false" 
                      to="/accreditations" class="nav-link">Accreditation</router-link>

          <router-link v-if="(header[1]) ? header[1].status : false" 
                      to="/programs" class="nav-link">Programs</router-link>

          <router-link v-if="(header[8]) ? header[8].status : false"
                      to="/our-certificates" class="nav-link">Certification</router-link>

          <router-link v-if="(header[2]) ? header[2].status : false" 
                      to="/memberships" class="nav-link">Membership</router-link>

          <router-link v-if="(header[3]) ? header[3].status : false" 
                      to="/about" class="nav-link">About</router-link>

          <router-link v-if="(header[4]) ? header[4].status : false" 
                      to="/contact" class="nav-link">Contact Us</router-link>
          <a href="javascript:;" 
            @click="opnPopular(nav.slug)" class="nav-link hidden-links" v-for="(nav, index) in popularSearch" :key="index">
            {{ nav.title }}
          </a>

            <router-link v-for="(pg, idy) in pages" :key="'p'+idy"
                      :to="{ name: 'show-page', params:{slug: pg.slug}}" 
                  class="nav-link"
                  :class="(pg.has_header) ? '' : 'hidden' ">
                  {{ pg.title }}
            </router-link>
        </div> -->
      </div>
    </div>
  </nav>

  <!-- Navbar Links -->
  <div class="navbar-container" v-bind:class="{'active': toggleMenu}">
    <!-- <div class="navbar-backdrop" v-bind:class="{'active': toggleMenu}"></div> -->
    <div class="navbar-content">
      <nav>
        <div class="container-fluid">
          <div class="navbar">
            <router-link to="/" class="nav-logo d-flex align-items-center">
              <img :src="logo.logo" class="img-fluid" alt="" />
              <div class="logo-title" v-html="logo.title"></div>
            </router-link>
            <button @click="toggleMenu = false" class="close-navbar d-flex align-items-center black-color"><span class="icon-cancel mr-3"></span> <span class="cm-text">Close Menu</span></button>
          </div>
        </div>
      </nav>

      <div class="navbar-body">
        <div class="navbar-center-items">
          <div class="nci-center-vertical">

            <div class="nci-link" v-if="(header[3]) ? header[3].status : false">
              <router-link class="nacei-link" to="/about">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[3].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[3].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link"  v-if="(header[1]) ? header[1].status : false">
              <router-link class="nacei-link" to="/programs">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[1].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[1].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link" v-if="(header[9]) ? header[9].status : false">
              <router-link class="nacei-link" to="/our-services">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[9].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[9].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link" v-if="(header[10]) ? header[10].status : false">
              <router-link class="nacei-link" to="/our-partners">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[10].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[10].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link" v-if="(header[4]) ? header[4].status : false">
              <router-link class="nacei-link" to="/contact">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[4].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[4].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link" v-if="(header[0]) ? header[0].status : false">
              <router-link class="nacei-link" to="/accreditations">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[0].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[0].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link" v-if="(header[2]) ? header[2].status : false">
              <router-link class="nacei-link" to="/memberships">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[2].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[2].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link"  v-if="(header[5]) ? header[5].status : false">
              <router-link class="nacei-link" to="/privacy">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[5].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[5].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link"  v-if="(header[6]) ? header[6].status : false">
              <router-link class="nacei-link" to="/events">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[6].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[6].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link"  v-if="(header[7]) ? header[7].status : false">
              <router-link class="nacei-link" to="/online-trainings">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[7].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[7].bgTitle }} page</div>
              </router-link>
            </div>

            <div class="nci-link"  v-if="(header[8]) ? header[8].status : false">
              <router-link class="nacei-link" to="/our-certificates">
                <div class="nci-link-parent d-flex align-items-center">
                  <div class="nci-line-parent d-flex align-items-center">
                    <span class="nci-line"></span>
                  </div>
                  <div class="nci-title">{{ header[8].bgTitle }}</div>
                </div>
                <div class="nci-text">Back to {{ header[8].bgTitle }} page</div>
              </router-link>
            </div>

          </div>
        </div>
      </div>

      <div class="navbar-footer">
        <div class="black-color container-fluid">
          2021 All Rights Reserved  © AGMEP
          <router-link class="nf-link black-color"
              v-if="(header[5]) ? header[5].status : false"
              to="/privacy"> Privacy Policy
          </router-link>
          
        </div>
      </div>
    </div>
  </div>
  <!-- Navbar Links -->

  <SearchPopup v-if="toggleSearch" />
  </div>
</template>

<style scoped="">
.hidden { display: none !important }
.dropdown-menu {
  left: auto;
  right: 0;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  padding: 10px 0;
  display: flex;
  align-items: center;
  height: 106.16px;
}
nav.active {
  background: #274379;
}
.navbar-container nav {
  background: none !important;
}
.navbar {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-between;
}
.nav-links {
  display: flex;
}
.nav-link {
  color: #ffffff;
  font-size: 20px
}
.nav-link:hover {
  color: #cecece
}
.nav-tools {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.nav-tools .icon:before,
.nav-tools span {
  color: #fff;
  font-size: 26px;
}
.nav-logo img {
  width: 94px;
}
.logo-title {
  font-weight: bold;
  font-size: 37px;
  line-height: 1;
}
.vertical-line {
  color: #fff;
  font-size: 18px;
}
.nav-su {
  display: flex;
}
.nav-links {
  display: block;
  position: fixed;
  top: 68px;
  left: 0;
  width: 100%;
  height: calc(100% - 68px);
  z-index: 19;
  padding: 0 15px;
  border-top: 1px solid #afafaf;
  background: #00166F;
}
.nav-links .nav-link {
  padding-left: 0;
  padding-right: 0;
  font-size: 32px;
  border-bottom: 1px solid #afafaf;
}
.nav-su .icon {
  padding: 3px 3px;
}
.menu-btn {
  width: 41px;
  height: 24px;
  position: relative;
  cursor: pointer;
  margin-left: 11px;
}
.menu-btn span {
  background: #fff;
  height: 3px;
  position: absolute;
  right: 0;
  display: block;
}
.bottom-bar {
  width: 30%;
  bottom: 0;
}
.middle-bar {
  width: 100%;
  top: 47%;
}
.top-bar {
  top: 0;
  width: 100%;
}
.menu-text {
  position: absolute;
  bottom: -4px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
}

/* Navbar Links */
.navbar-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 0.8s cubic-bezier(0.7,0,0.3,1) .1s;
}
.navbar-container nav {
  position: static;
}
.navbar-container.active {
  transform: translateY(0);
}
.navbar-center-items {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
}
.nci-center-vertical {
  max-width: 203px;
}
.navbar-container.active .navbar-center-items {
  opacity: 1;
}
/* .navbar-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-99%);
  background: radial-gradient(
    circle at 0% 0%,
    rgb(0, 67, 122) 0%,
    #2D2E76 100%
  );
} */
.navbar-container {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.navbar-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.navbar-header {
  padding: 30px 70px;
}
.close-navbar .icon-cancel {
  font-weight: bold;
}
.navbar-body {
  height: calc(100% - 165px);
  overflow-y: auto;
  padding: 30px 0;
  /* display: flex;
  place-items: center;
  justify-content: center; */
}

.nci-link {
  margin-bottom: 23px;
}
.nci-link:last-of-type {
  margin: 0;
}
.nci-line-parent {
  position: relative
}
.nci-line {
  width: 0;
  height: 1.5px;
  background: rgb(76, 199, 244);
  transition: width .2s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.nacei-link:hover .nci-line {
  width: 42px;
  margin-right: 12px;
}
.nacei-link:hover .nci-title {
  color: rgb(76, 199, 244);
}
.nci-title {
  font-size: 1.75em;
  letter-spacing: .02em;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.nacei-link:hover .nci-title {
  /* left: 54px; */
  transform: translateX(54px);
}
.nci-text {
  font-size: 19px;
  font-weight: 400;
  color: #656565;
  white-space: nowrap;
}

.close-navbar:hover span {
  color: rgb(76, 199, 244);
}
.navbar-header .icon-cancel {
  font-weight: 600;
  font-size: 19px;
}
.cm-text {
  font-size: 16px;
  font-weight: 600;
}
.nv-link {
  display: inline-block;
  color: #fff !important;
  padding: 2px 14px;
  font-size: 40px;
  border: 1px solid transparent;
  margin-left: -14px;
}
.nv-link:active {
  border: 1px solid rgb(76, 199, 244);
}
.nv-link:hover {
  color: rgb(76, 199, 244) !important;
}
.nb-top-links .sm-title {
  font-weight: bold;
}
.top-links .tp-links {
  display: block;
  color: #fff;
  font-size: 22px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.nb-border-left {
  border-left: 1px solid #fff;
  padding-left: 70px;
}
.nb-view img {
  width: 370px;
  height: 192px;
  object-fit: cover;
}
.nb-view .title {
  line-height: 1;
}
.nf-link:hover {
  color: rgb(76, 199, 244) !important;
}
.nf-link {
  display: inline-block;
  line-height: 1;
  font-size: 17px;
  text-decoration: underline !important;
  font-weight: 500;
  color: #000;
  border-right: 2px solid #000;
  padding-right: 10px;
  margin-right: 10px;
}
.nf-link:last-of-type {
  border: none;
  padding: 0;
  margin: 0;
}
.navbar-footer {
  line-height: 1;
  padding-top: 20px;
  font-size: 17px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #000;
}
/* Navbar Links */


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .nci-title {
    font-size: 1.4rem
  }
}

@media (max-width: 767.98px) {
  .nav-logo img {
    width: 80px;
  }
  .logo-title {
    display: block;
  }
  nav {
    height: 93.33px;
  }
  .nav-tools {
    margin: 0;
  }

  /* Navbar Links */
  .navbar-footer {
    font-size: 14px;
  }
  .nf-link {
    font-size: 14px;
  }
  .navbar-body-content {
    display: block !important;
  }
  .nb-border-left {
    padding-left: 0;
    border: none;
  }
  .nb-links {
    margin-bottom: 50px;
  }
  .nv-link {
    padding: 2px 11px;
    margin-left: -11px;
  }
  .navbar-header {
    padding: 30px 40px;
  }
  .nb-view img {
    width: auto;
    height: auto;
  }
  .navbar-body {
    height: calc(100% - 147px);
    padding: 0;
    place-items: center;
    display: flex;
  }
  .navbar-center-items {
    height: auto;
    margin: auto;
    padding: 25px 0;
  }
  /* Navbar Links */
}

@media (max-width: 991.98px) {
  
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Navbar Links */
  .nb-view img {
    width: 280px;
  }
  .nb-border-left {
    padding-left: 40px;
  }
  .navbar-center-items {
    height: auto;
  }
  .navbar-body {
    padding: 0;
  }
  /* Navbar Links */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-center-items {
    height: auto;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
</style>

<script>
import axios from "axios";
import SearchPopup from './SearchPopup';

export default {
  name: "Navbar",
  props: ['close'],
  components: {
    SearchPopup
  },
  data() {
    return {
      pgLoading: false,
      logo: [],
      header: [],
      pages: [],
      socials: [],
      searchPage: false,
      avatar: '',
      authenticated: false,
      toggleSearch: false,
      popularSearch: [],
      toggleMenu: false,
      toggleNavbarBg: false
    };
  },
  created() {
    axios
        .get(window.baseURL + "/popularSearch")
        .then((res) => {
          const data = res;
          this.popularSearch = res.data.rows;
          console.log(data.data);
        })
        .catch(() => { });
    
    if (!localStorage.getItem("access_token")) {
      this.authenticated = false;
    } else {
      this.authenticated = true;
    }

    if(!localStorage.getItem("avatar")) {
      this.avatar = false;
    } else {
      this.avatar = localStorage.getItem("avatar");
    }

    this.fetchLogo();
    this.fetchPages();
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    opnPopular(slug) {
      this.$router.push({ name: "show-popular-search", params:{slug: slug }});
      // refersh
      location.reload();
    },

    fetchLogo() {
      // Get Navbar Links
      axios
        .get(window.baseURL + "/setting")
        .then((res) => {
          var data = res.data;
          this.logo = res.data.logo;
          this.socials = res.data.social;
          this.header = res.data.header;
          console.log(res.data.logo);
        })
        .catch(() => {});
    },


    //Get Navbar Links in Big Sizes
    fetchPages(){
    axios
      .get(window.baseURL + "/pages")
      .then((res) => {
        var data = res.data.rows;
        this.pages = res.data.rows;
        console.log('data', res);
      })
      .catch(() => {});
    },

    logout(){
      localStorage.clear();
      this.$router.push({ name: 'login' });
    },

  },
  mounted() {
    if ($(window).scrollTop() > 0) {
      $("nav").addClass('active');
    } 
    
    $(window).scroll(function () {
      if ($(window).scrollTop() > 0) {
          $("nav").addClass('active');
      } else {
          $("nav").removeClass('active');
      }
    });
  }
};
</script>
