<template>
  <div class="page-container">
    <div class="certificate-nav">
      <div class="container-fluid">
        <div class="certificate-bar">
          
          <router-link :to="{ name: 'home' }" class="big-logo">
            <img :src="logo.logo" alt="Logo"/>
          </router-link>

          <router-link to="/me/profile" class="dropdown">
            <img :src="(avatar) ? avatar : require('../../assets/img/avatar.jpg')" 
                class="img-fluid" alt=""  style="border-radius: 50%" />
            <div class="title">{{ username }}</div>
          </router-link>

        </div>
      </div>
    </div>

    <Navbar :certificatePage="true" />

    <div class="main-certificate-container container-fluid">
      <div class="main-certificate">
        <div class="main-certificate-detials">
          <div class="verfication">Verification</div>
          <div class="bg-title">My Certificate</div>
          <div v-if="errors" class="alert alert-danger text-left">{{ errors }}</div>
          <div v-if="success" class="alert alert-success text-left">{{ success }}</div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              name="serial"
              placeholder="Certificate Serial No. Here DIP/USA/108TMR99830"
              v-model="keyword"
            />
          </div>
          <div class="details pt-3 pb-3">
            <div class="header">Certificate details</div>
            <div class="details-content mt-3">
              <div class="details-row">
                <div class="title">Name:</div>
                <div class="data">John Adam</div>
              </div>
              <div class="details-row">
                <div class="title">Course:</div>
                <div class="data">Coating Technican</div>
              </div>
              <div class="details-row">
                <div class="title">Course Date:</div>
                <div class="data">July 13, 2020 - July 15, 2020</div>
              </div>
              <div class="details-row">
                <div class="title">Grade:</div>
                <div class="data">Excellent</div>
              </div>
              <div class="details-row">
                <div class="title">Date:</div>
                <div class="data">July 19, 2020</div>
              </div>
              <div class="details-row">
                <div class="title">Valid To:</div>
                <div class="data">Valid 2 Years</div>
              </div>
            </div>
          </div>
          <button
            :disabled="btnLoading"
            @click="sendKeyword()"
            class="verify-btn main-btn-backdrop"
          >
            <img v-if="btnLoading" src="../../assets/loader.svg" class="sm-loader" alt />
            <span v-else>Verify</span>
          </button>
          <div class="verify-links">
            <div class="verfication text">Verify other certificates</div>
            <div class="links">
              <router-link to="/me/certificate">Professional Certificate</router-link>
              <router-link to="/me/certificate">Professional Diploma</router-link>
            </div>
          </div>
        </div>
        <div class="main-certificate-view">
          <div class="c-frame certificate-view-vertically">
            <img src="../../assets/img/Certificate-of-Achievement.png" class="img-fluid" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="../../components/common/css/MainCertificate.css">
</style>

<style scoped>
.dropdown img {
  width: 50px;
  height: 50px;
}
.main-certificate-view {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
</style>

<script>
import Search from "@/components/Search.vue";
import Avatar from "@/components/Avatar.vue";
import Navbar from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "MainCertificate",
  components: {
    Search,
    Avatar,
    Navbar,
  },
  data() {
    return {
      username: "",
      keyword: "",
      avatar: '',
      logo: '',
      errors: false,
      success: false,
      btnLoading: false,
    };
  },
  methods: {
    sendKeyword: function () {
      // this.btnLoading = true;
      // axios.defaults.headers.common = {
      //   "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      // };
      // const options = {
      //   url: window.baseURL + "/popularSearch/certificate",
      //   method: "POST",
      //   data: {
      //     keyword: this.keyword,
      //   },
      // };
      // axios(options)
      //   .then((res) => {
      //     this.btnLoading = false;
      //     this.errors = false;
      //     this.success = "Success Verify.";
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     this.btnLoading = false;
      //     this.success = false;
      //     this.errors = err.response ? err.response.data.message : "" + err;
      //   })
      //   .finally(() => {});
    },


    fetchLogo() {
      axios
        .get(window.baseURL + "/setting")
        .then((res) => {
          var data = res.data.logo;
          this.logo = data;
        })
        .catch(() => {});
    },

  },
  created() {
    if(!localStorage.getItem("avatar")) {
      this.avatar = false;
    } else {
      this.avatar = localStorage.getItem("avatar");
    }

    // Check Auth
    if (!localStorage.getItem("access_token")) {
      this.$router.push({ name: "login" });
    }
    this.username = localStorage.getItem("user_name");

    this.fetchLogo();
  },
};
</script>
