<template>
  <div class="page-container">
    <div class="certificate-nav">
      <div class="container-fluid">
        <div class="certificate-bar">
          <router-link :to="{ name: 'home' }" class="big-logo">
            <img :src="logo.logo" alt="Logo" />
          </router-link>
          <!-- 
          <router-link to="/me/profile" class="dropdown">
            <img :src="(avatar) ? avatar : require('../../assets/img/avatar.jpg')" 
                class="img-fluid" alt=""  style="border-radius: 50%" />
            <div class="title">{{ username }}</div>
          </router-link> -->
        </div>
      </div>
    </div>

    <Navbar :certificatePage="true" />

    <div
      class="main-certificate-container container-fluid"
      style="display: none"
    >
      <div class="main-certificate">
        <div class="main-certificate-detials">
          <div class="verfication">Verification</div>
          <div class="bg-title">My Certificate</div>
          <div v-if="errors" class="alert alert-danger text-left">
            {{ errors }}
          </div>
          <div v-if="success" class="alert alert-success text-left">
            {{ success }}
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              name="serial"
              placeholder="Certificate Serial No. Here DIP/USA/108TMR99830"
              v-model="keyword"
            />
          </div>
          <div class="details pt-3 pb-3">
            <div class="header">Certificate Details</div>
            <div class="details-content mt-3">
              <div class="details-row">
                <div class="title">Name:</div>
                <div
                  class="data"
                  v-html="cert.name ? cert.name : 'John Adam'"
                ></div>
              </div>
              <div class="details-row">
                <div class="title">Course:</div>
                <div
                  class="data"
                  v-html="
                    cert.course_name ? cert.course_name : 'Coating Technican'
                  "
                ></div>
              </div>
              <div class="details-row">
                <div class="title">Course Date:</div>
                <div
                  class="data"
                  v-html="
                    cert.course_date
                      ? cert.course_date
                      : 'July 13, 2020 - July 15, 2020'
                  "
                ></div>
              </div>
              <div class="details-row">
                <div class="title">Grade:</div>
                <div
                  class="data"
                  v-html="cert.grade ? cert.grade : 'Excellent'"
                ></div>
              </div>
              <div class="details-row">
                <div class="title">Date:</div>
                <div
                  class="data"
                  v-html="cert.date ? cert.date : 'July 19, 2020'"
                ></div>
              </div>
              <div class="details-row">
                <div class="title">Valid To:</div>
                <div
                  class="data"
                  v-html="cert.validTo ? cert.validTo : 'Valid 2 Years'"
                ></div>
              </div>
            </div>
          </div>
          <button
            :disabled="btnLoading"
            @click="sendKeyword()"
            class="verify-btn main-btn-backdrop"
          >
            <img
              v-if="btnLoading"
              src="../../assets/loader.svg"
              class="sm-loader"
              alt
            />
            <span v-else>Verify</span>
          </button>
          <div class="verify-links">
            <div class="verfication text">Verify other certificates</div>
            <div class="links">
              <router-link to="/me/certificate-achievement">Skills</router-link>
              <router-link to="/me/certificate-achievement"
                >Training Attendance</router-link
              >
              <router-link to="/me/certificate-achievement"
                >Workshop</router-link
              >
            </div>
          </div>
        </div>
        <div class="main-certificate-view">
          <!-- :style="{ backgroundImage: `url(` + require('../assets/img/Certificate-of-Diploma.png') + `)` }" -->
          <div class="c-frame">
            <img
              :src="
                cert.image
                  ? cert.image
                  : '../../assets/img/Certificate-of-Diploma.png'
              "
              class="img-fluid"
              alt
            />
          </div>
        </div>
      </div>
    </div>

    <iframe
      src="https://www.agmep.group/Verify/AGMEP.html"
      width="100%"
      height="1200px"
      frameborder="0"
      scrolling="no"
    ></iframe>
  </div>
</template>

<style scoped src="../../components/common/css/MainCertificate.css"></style>

<style scoped>
.dropdown img {
  width: 50px;
  height: 50px;
}
.main-certificate-view {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
</style>

<style scoped>
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>

<script>
import Search from "@/components/Search.vue";
import Avatar from "@/components/Avatar.vue";
import Navbar from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "MainCertificate",
  components: {
    Search,
    Avatar,
    Navbar,
  },
  data() {
    return {
      cert: {
        name: "",
        course_name: "",
        course_date: "",
        date: "",
        grade: "",
        validTo: "",
        image: "",
      },
      username: "",
      keyword: "",
      avatar: "",
      logo: "",
      errors: false,
      success: false,
      btnLoading: false,
    };
  },
  methods: {
    sendKeyword: function() {
      this.btnLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
      };
      const options = {
        url: "https://demo.agmep.group/api/Client/GetCertificateDetail",
        method: "POST",
        data: {
          certificateno: this.keyword,
          institution: "aips",
        },
      };
      axios(options)
        .then((res) => {
          this.btnLoading = false;
          this.errors = false;
          this.success = "Certificate found successfully.";

          this.cert.name = res.data.ClientName;
          this.cert.course_name = res.data.CourseName;
          this.cert.course_date = res.data.ValidDate;
          this.cert.date = res.data.IssuedOn;
          this.cert.grade = res.data.Grade;
          this.cert.validTo = res.data.InvalidDate;
          this.cert.image = res.data.CertificatePath;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.success = false;
          this.errors = "Certificate not exists.";
        })
        .finally(() => {});
    },

    fetchLogo() {
      axios
        .get(window.baseURL + "/setting")
        .then((res) => {
          var data = res.data.logo;
          this.logo = data;
        })
        .catch(() => {});
    },
  },
  created() {
    if (!localStorage.getItem("avatar")) {
      this.avatar = false;
    } else {
      this.avatar = localStorage.getItem("avatar");
    }

    // Check Auth
    // if (!localStorage.getItem("access_token")) {
    //   this.$router.push({ name: "login" });
    // }
    this.username = localStorage.getItem("user_name");
    this.fetchLogo();
  },
};
</script>
